import {Box, Button} from "@mui/material";
import {ApplicationElementsMessages} from "utils/AppMessages/AppMessages";
import FlexBox from "../../components/FlexBox";
import UkoAvatar from "../../components/UkoAvatar";
import {H6} from "../../components/Typography";

const ColumnTicketManagement = [
    {
        Header: "عنوان",
        accessor: "title",
        minWidth: 200,
        Cell: ({row}: any) => {
            const {title, icon} = row.original;
            return (
                <FlexBox alignItems="center">
                    <img
                        hidden={icon.length == 0}
                        src={icon}
                        style={{
                            width: 70,
                            height: 70,
                            borderRadius: 70,
                            border: "2px solid #e1e1e1",
                        }}
                    />
                    <div hidden={icon.length > 0}>
                        <UkoAvatar/>
                    </div>
                    <FlexBox flexDirection="column" mr={1}>
                        <H6 color="text.primary">
                            {title}
                        </H6>
                    </FlexBox>
                </FlexBox>
            );
        },
    },
    {
        Header: ApplicationElementsMessages.description,
        accessor: "description",
        minWidth: 200,
    },
    {
        Header: ApplicationElementsMessages.Role,
        accessor: "roleId",
        minWidth: 200,
        Cell: ({row}: any) => {
            const {role} = row.original;
            return (
                <H6>
                    {role.displayName}
                </H6>
            );
        },
    },
    {
        Header: "عملیات",
        accessor: "action",
        minWidth: 200,
        Cell: (props: any) => {
            return (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        direction: "ltr",
                        alignItems: "center",
                        textAlign: "center",
                        alignContent: "center",
                    }}
                >
                    <Button name="delete" variant="outlined">
                        {ApplicationElementsMessages.delete}
                    </Button>
                    <Box mr={1}/>
                    <Button name="edit" variant="outlined">
                        {ApplicationElementsMessages.edit}
                    </Button>
                </Box>
            );
        },
    },
];

export default ColumnTicketManagement;
