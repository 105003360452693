import { CurrentUserRolePermissionDto } from "model/RoleModels/CurrentUserRolePermissionDto";
import JwtToken from "../remote/model/JwtToken";
import { localStorageHelper } from "./localStorageHelper";

export class UserManager {
  private static userManager: UserManager | null = null;
  private Token = "Token";
  private RefreshToken = "RefreshToken";
  private UserPermissions = "UserPermission";

  static get() {
    if (this.userManager == null) {
      this.userManager = new UserManager();
    }
    return this.userManager;
  }

  hasToken(): Boolean {
    return (
      this.loadToken() !== undefined &&
      this.loadToken().token !== "" &&
      this.loadToken().refreshToken !== ""
    );
  }

  loadToken(): JwtToken {
    const token = localStorageHelper.load(this.Token);
    const refreshToken = localStorageHelper.load(this.RefreshToken);
    return {
      token: token ? token : "",
      refreshToken: refreshToken ? refreshToken : "",
    };
  }

  saveToken(jwtToken: JwtToken) {
    localStorageHelper.store(this.Token, jwtToken.token);
    localStorageHelper.store(this.RefreshToken, jwtToken.refreshToken);
  }

  deleteToken() {
    localStorageHelper.remove(this.Token);
    localStorageHelper.remove(this.RefreshToken);
    localStorageHelper.remove(this.UserPermissions);
  }

  loadUserRolePermissions(): CurrentUserRolePermissionDto {
    const userRolePermissions = localStorageHelper.load(this.UserPermissions);
    if (userRolePermissions) {
      return JSON.parse(userRolePermissions);
    }
    return {
      permissions: [],
      role: {
        displayName: "",
        id: "",
        name: "",
        notificationReceiver: "",
      },
    };
  }

  saveUserRolePermissions(model: CurrentUserRolePermissionDto) {
    if (model) {
      localStorageHelper.store(this.UserPermissions, JSON.stringify(model));
    }
  }
}
