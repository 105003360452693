import { Box, Button, styled } from "@mui/material";
import FlexBox from "components/FlexBox";
import CustomTable from "components/userManagement/CustomTable";
import useTitle from "hooks/useTitle";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Remote from "../../remote/Remote";
import ApiService from "../../remote/ApiService";
import IApiResultData from "../../remote/model/IApiResultData";
import toast from "react-hot-toast";

import AppContainer from "../../components/core/AppContainer";
import { ApplicationElementsMessages } from "utils/AppMessages/AppMessages";
import { LayoutNoData } from "../../components/core/LayoutNoData";
import TransactionColumnShape from "./columnInstallmentPayments";
import IPagedResultData from "../../remote/model/IPagedResultData";
import { PagingRequestDto } from "../../model/paging/PagingRequestDto";
import SearchInput from "../../components/SearchInput";
import { InstallmentPaymentsListDto } from "../../model/installmentPayments/InstallmentPaymentsListDto";
import { LoanRequestPaymentStatus } from "../../model/Enums/LoanRequestPaymentStatus";
import { UserDto } from "../../model/UserDto";
import IApiResult from "../../remote/model/IApiResult";

const StyledFlexBox = styled(FlexBox)(({ theme }) => ({
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  marginBottom: 20,
  [theme.breakpoints.down(500)]: {
    width: "100%",
    "& .MuiInputBase-root": { maxWidth: "100%" },
    "& .MuiButton-root": {
      width: "100%",
      marginTop: 15,
    },
  },
}));

const InstallmentPaymentsList = () => {
  useTitle(ApplicationElementsMessages.installmentPayments);
  const navigate = useNavigate();
  const [dataList, setDataList] = useState<InstallmentPaymentsListDto[]>([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState<string>("");
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  let paging = new PagingRequestDto();

  useEffect(() => {
    getInstallmentPayments();
  }, []);

  const getInstallmentPayments = () => {
    Remote.call(
      ApiService.getInstallmentPayments(search, paging.get()),
      (
        response: IApiResultData<IPagedResultData<InstallmentPaymentsListDto>>
      ) => {
        if (response.data) {
          setDataList(response.data.items);
          setTotalPages(response.data.totalPages);
          setCurrentPage(response.data.currentPage);
        }
      },
      (onProgress: boolean) => {
        setLoading(onProgress);
      },
      (error: string) => {
        toast.error(error);
      }
    );
  };

  const onClickSearch = () => {
    setPage(1);
    getInstallmentPayments();
  };

  const onChangePage = (_e: ChangeEvent<unknown>, page: number) => {
    setPage(page);
    getInstallmentPayments();
  };

  const setPage = (page: number) => {
    paging.setPage(page);
    setCurrentPage(page);
  };

  const onClick = (id: any, data: InstallmentPaymentsListDto) => {
    if (id === "details") {
      if (data && data.loanRequestId) {
        navigate(`/dashboard/requestLoanDetails/${data.loanRequestId}`);
      }
    } else if (id === "confirmed") {
      if (data && data.id) {
        setStatePayment(data.id, LoanRequestPaymentStatus.Confirmed);
      }
    } else if (id === "rejected") {
      if (data && data.id) {
        setStatePayment(data.id, LoanRequestPaymentStatus.Rejected);
      }
    }
  };

  const setStatePayment = (id: number, status: LoanRequestPaymentStatus) => {
    Remote.call(
      ApiService.setLoanRequestPaymentStatus(id, status),
      (response: IApiResult) => {
        if (response.isSuccess) {
          toast.success(response.message);
          getInstallmentPayments();
        } else {
          toast.error(response.message);
        }
      },
      (onProgress: boolean) => {
        setLoading(onProgress);
      },
      (error: string) => {
        toast.error(error);
      }
    );
  };

  return (
    <AppContainer isLoadingSpinner={loading}>
      <Box pt={2} pb={4}>
        <StyledFlexBox>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <SearchInput
              placeholder={ApplicationElementsMessages.hintTransactionSearch}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setSearch(event.target.value);
              }}
            />
            <Box mr={1} ml={1} />
            <Button
              style={{ marginRight: 5 }}
              variant="contained"
              onClick={onClickSearch}
            >
              {ApplicationElementsMessages.Search}
            </Button>
          </Box>
        </StyledFlexBox>
        <LayoutNoData show={dataList.length !== 0} />
        <div hidden={dataList.length === 0}>
          <CustomTable
            columnShape={TransactionColumnShape}
            data={dataList}
            totalPage={totalPages}
            currentPage={currentPage}
            onChangePage={onChangePage}
            rowClick={onClick}
          />
        </div>
      </Box>
    </AppContainer>
  );
};

export default InstallmentPaymentsList;
