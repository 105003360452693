import { ApplicationElementsMessages } from "utils/AppMessages/AppMessages";

export enum PensionFundType {
  SocialSecurity = 1,
  RetirementOrganization = 2,
}

export const PensionFundTypeLabel = new Map<PensionFundType, string>([
  [PensionFundType.SocialSecurity, "تامین اجتماعی"],
  [PensionFundType.RetirementOrganization, "سازمان بازنشستگی"],
]);

export const PensionFundTypeList = [
  { id: 0, value: ApplicationElementsMessages.Choose },
  {
    id: PensionFundType.SocialSecurity,
    value: PensionFundTypeLabel.get(PensionFundType.SocialSecurity),
  },
  {
    id: PensionFundType.RetirementOrganization,
    value: PensionFundTypeLabel.get(PensionFundType.RetirementOrganization),
  },
];
