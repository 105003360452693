import { ApplicationElementsMessages } from "../../utils/AppMessages/AppMessages";

export enum MembershipFeeGroupType {
  Main = 1,
  NotMain = 2,
}

export const MembershipFeeGroupTypeLabel = new Map<
  MembershipFeeGroupType,
  string
>([
  [
    MembershipFeeGroupType.Main,
    ApplicationElementsMessages.membershipFeeGroupMain,
  ],
  [
    MembershipFeeGroupType.NotMain,
    ApplicationElementsMessages.membershipFeeGroupNotMain,
  ],
]);

export const findMembershipFeeGroupTypeValue = (
  numericValue: number
): MembershipFeeGroupType => {
  const membershipType = MembershipFeeGroupType[
    numericValue
  ] as unknown as MembershipFeeGroupType;
  if (membershipType === undefined) {
    throw new Error("Invalid membership");
  }
  return membershipType;
};

export const MembershipFeeGroupTypeList = [
  { id: 0, value: ApplicationElementsMessages.Choose },
  {
    id: MembershipFeeGroupType.Main,
    value: MembershipFeeGroupTypeLabel.get(MembershipFeeGroupType.Main),
  },
  {
    id: MembershipFeeGroupType.NotMain,
    value: MembershipFeeGroupTypeLabel.get(MembershipFeeGroupType.NotMain),
  },
];
