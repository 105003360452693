import FlexBox from "components/FlexBox";
import {Box, Button} from "@mui/material";

const columnOperator = [
    {
        Header: "نام",
        accessor: "name",
        minWidth: 150,
    },
    {
        Header: "نام خانوادگی",
        accessor: "family",
        minWidth: 150,
    },
    {
        Header: "نام کاربری",
        accessor: "userName",
        minWidth: 150,
    },
    {
        Header: "نقش",
        accessor: "role",
        minWidth: 150,
    },
    {
        Header: "عملیات",
        accessor: "action",
        minWidth: 200,
        Cell: ({row}: any) => {
            const {onEdit, onDeActive} = row.original;
            return (
                <FlexBox alignItems="center">
                    <FlexBox flexDirection="row">
                        <Button name="edit" variant="outlined" onClick={onEdit}>
                            ویرایش
                        </Button>
                        <Box mr={1}/>
                        <Button name="deActive" variant="outlined" onClick={onDeActive}>
                            حذف
                        </Button>
                    </FlexBox>
                </FlexBox>
            );
        },
    },
];

export default columnOperator;
