import { ApplicationElementsMessages } from "utils/AppMessages/AppMessages";

export enum JobType {
  Employed = 1,
  Retired = 2,
}

export const JobTypeLabel = new Map<JobType, string>([
  [JobType.Employed, "شاغل"],
  [JobType.Retired, "بازنشسته"],
]);

export const JobTypeList = [
  { id: 0, value: ApplicationElementsMessages.Choose },
  { id: JobType.Employed, value: JobTypeLabel.get(JobType.Employed) },
  { id: JobType.Retired, value: JobTypeLabel.get(JobType.Retired) },
];
