import InstallmentPaymentsList from "../pages/installmentPayments/InstallmentPaymentsList";

const Url = {
  /**Auth*/
  login: "Auth/Login",
  changeAdminPassword: "Auth/changeAdminPassword",
  getUserPermissions: "Auth/GetCurrentUserPermissions",
  refreshToken: "Client/RefreshToken",
  getJobLocationList: "Client/GetJobLocationList",

  /**User*/
  getUsers: "Users/GetUsers",
  getUserInfo: "Users/GetUserInfo",
  modifyUserInfo: "Users/ModifyUserInfo",
  uploadMembershipFeeFile: "Users/UploadMembershipFeeFile",
  uploadUsersListFile: "Users/UploadUsersListFile",
  uploadUsersLoanFile: "Users/UploadUsersLoanFile",
  getUserLoan: "Users/GetUserLoan",
  getUserMembershipFeeInfo: "Users/GetUserMembershipFeeInfo",
  changeUserStatus: "Users/ChangeUserStatus",
  setMembershiFeePayment: "Users/SetMembershiFeePayment",

  /**Role*/
  getRoles: "Roles/GetRoles",
  getRoleInfo: "Roles/GetRoleInfo",
  getRolePermissions: "Roles/GetRolePermissions",
  modifyRolePermissions: "Roles/ModifyPermissions",
  addRole: "Roles/Create",
  editRole: "Roles/Edit",

  /**Public*/
  getLogEntries: "LogEntries/GetLogEntries",
  getSmsRemain: "Sms/getSmsRemain",
  getSettings: "Setting/getSettings",
  modifySettings: "Setting/modifySettings",
  getDashboardSummary: "Dashboard/GetDashboardSummary",

  /**BaseData*/
  initializeDynamicPermission: "BaseData/InitializeDynamicPermissionToDb",

  /**Operator*/
  getOperators: "ManageOperators/GetOperatorList",
  getOperatorById: "ManageOperators/getOperatorById",
  addOperator: "ManageOperators/Create",
  editOperator: "ManageOperators/Edit",
  deleteOperator: "ManageOperators/Delete",

  /**Ticket*/
  ticketItemsManagement: "TicketItemsManagement",
  getTicketItemById: "TicketItemsManagement/getById",
  getTickets: "Tickets/GetTickets",
  getTicketMessages: "Tickets/GetTicketMessages",
  addNewMessage: "Tickets/AddNewMessage",

  /**Transactions*/
  getTransactions: "Transactions/getTransactions",

  /**Requests Loan*/
  getRequestsLoan: "LoanRequests/GetLoanRequests",
  getRequestLoanById: "LoanRequests/GetLoanRequestDetail",
  updateStatusRequestLoan: "LoanRequests/SetLoanRequestStatus",
  setLoanRequestPaymentStatus: "LoanRequests/SetLoanRequestPaymentStatus",
  setLoanRequestInstallmentDuration:
    "LoanRequests/SetLoanRequestInstallmentDuration",

  /**Current Loan*/
  getCurrentLoan: "LoanRequests/GetRemainingInstallmentLoanRequests",

  /**Installment Payments*/
  getInstallmentPayments: "LoanRequests/GetUserInstallmentPayments",

};

export default Url;
