import {Fade, Modal, styled} from "@mui/material";
import {ReactNode} from "react";
import {ApplicationElementsMessages} from "utils/AppMessages/AppMessages";
import {H3, H4} from "../../Typography";

const ModalApp = (props: {
    children?: ReactNode;
    title?: string;
    open: boolean;
    maxWidth?: number;
    onClose?: (close: boolean) => void;
    positiveText?: string;
    onPositive?: (result: any) => void;
}) => {
    const handleOnClick = (event: any) => {
        if (props?.onPositive != null) props?.onPositive(true);
    };

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={props?.open}
                onClose={() => (props?.onClose ? props?.onClose(false) : undefined)}
                closeAfterTransition
                BackdropProps={{
                    timeout: 500,
                }}
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Fade in={props?.open}>
                    <div
                        style={{
                            maxWidth: props?.maxWidth ? props?.maxWidth : 500,
                            background: "#2499EF",
                            border: "3px solid #2499EF",
                            borderRadius: "15px",
                        }}
                    >
                        <StyledTitle id="transition-modal-title">
                            {props?.title}
                        </StyledTitle>
                        <div
                            style={{
                                maxWidth: props?.maxWidth ? props?.maxWidth : 500,
                                background: "#ffffff",
                                paddingLeft: 20,
                                paddingRight: 20,
                                paddingBottom: 20,
                                borderRadius: "15px",
                            }}
                        >

                            <br/>
                            {props?.children}
                            <div hidden={props?.positiveText == null}>
                                <br/>
                                <StyledButton onClick={handleOnClick}>
                                    {props?.positiveText ? props?.positiveText : ApplicationElementsMessages.Submit}
                                </StyledButton>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};

const StyledTitle = styled(H3)(({theme}) => ({
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
    padding: 12,
}));

const StyledButton = styled(H4)(({theme}) => ({
    marginRight: 15,
    marginLeft: 15,
    cursor: "pointer",
    color: theme.palette.primary.main,
    "&:hover": {
        color: theme.palette.primary.dark,
    },
}));

export default ModalApp;
