import { Box, Button, Card, Grid } from "@mui/material";
import LightTextField from "components/LightTextField";
import { useFormik } from "formik";
import useTitle from "hooks/useTitle";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import Remote from "../../remote/Remote";
import ApiService from "../../remote/ApiService";
import { RoleDto } from "../../model/RoleModels/RoleDto";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { LoadingButton } from "@mui/lab";
import { ApplicationElementsMessages } from "utils/AppMessages/AppMessages";
import IApiResult from "remote/model/IApiResult";
import IApiResultData from "../../remote/model/IApiResultData";
import { CreateTicketItemDto } from "../../model/ticket/CreateTicketItemDto";
import ImagePicker from "../../components/core/ImagePicker";
import AppSelect from "../../components/core/Select/App-Select";
import { TicketItemDto } from "model/ticket/TicketItemDto";

const AddEditTicketItemsManagement = () => {
  useTitle(ApplicationElementsMessages.defineTicketItems);
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const { id } = useParams<{ id: string }>();
  const [ticketItem, setTicketItem] = useState<TicketItemDto>();
  const [roleList, setRoleList] = useState<any[]>([{}]);
  const [imageSelected, setImageSelected] = useState<string>();
  const [imageValue, setImageValue] = useState<string>();

  const initialValues = {
    title: ticketItem?.title ? ticketItem?.title : "",
    description: ticketItem?.description ? ticketItem?.description : "",
    icon: ticketItem?.icon ? ticketItem?.icon : "",
    roleId: ticketItem?.role?.id ? ticketItem?.role?.id : "",
  };

  useEffect(() => {
    getRoles();
    if (id) getTicketItemById();
  }, []);

  const getTicketItemById = () => {
    Remote.call(
      ApiService.getTicketItemById(id ? id : ""),
      (response: IApiResultData<TicketItemDto>) => {
        if (response.data) {
          setTicketItem(response.data);
          setImageValue(response.data.icon);
        } else {
          navigate("/dashboard/ticketItemsManagement");
        }
      },
      (onProgress: boolean) => {
        setLoading(onProgress);
      },
      (error: string) => {
        toast.error(error);
      }
    );
  };

  const getRoles = () => {
    Remote.call(
      ApiService.getRoles(),
      (response: IApiResultData<RoleDto[]>) => {
        if (response.data) {
          const result = [
            { id: "-1", value: ApplicationElementsMessages.Choose },
          ];
          response.data.forEach((item) => {
            result.push({
              id: item.id ? item.id : "",
              value: item.displayName ? item.displayName : "",
            });
          });
          setRoleList(result);
        }
      },
      (onProgress: boolean) => {
        setLoading(onProgress);
      },
      (error: string) => {
        toast.error(error);
      }
    );
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .min(3, "عنوان حداقل باید 3 کاراکتر باشد")
      .required("عنوان را صحیح وارد نمایید"),
    description: Yup.string()
      .min(3, "توضیحات حداقل باید 3 کاراکتر باشد")
      .required("توضیحات را صحیح وارد نمایید"),
  });

  const { values, errors, handleChange, handleSubmit, touched } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values: any) => {
      const data = new CreateTicketItemDto(
        id ? parseInt(id) : 0,
        values.title,
        values.description,
        imageSelected ? imageSelected : "",
        values.roleId
      );
      if (id) edit(data);
      else add(data);
    },
  });

  const add = (data: CreateTicketItemDto) => {
    Remote.call(
      ApiService.ticketItemCreate(data),
      (response: IApiResult) => {
        if (response.isSuccess) {
          toast.success(response.message);
          navigate("/dashboard/ticketItemsManagement");
        } else {
          toast.error(response.message);
        }
      },
      (onProgress: boolean) => {
        setLoading(onProgress);
      },
      (error: string) => {
        toast.error(error);
      }
    );
  };

  const edit = (data: CreateTicketItemDto) => {
    Remote.call(
      ApiService.ticketItemEdit(data),
      (response: IApiResult) => {
        if (response.isSuccess) {
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      },
      (onProgress: boolean) => {
        setLoading(onProgress);
      },
      (error: string) => {
        toast.error(error);
      }
    );
  };

  return (
    <Box pt={2} pb={4}>
      <Button
        variant="contained"
        onClick={() => navigate("/dashboard/ticketItemsManagement")}
      >
        {ApplicationElementsMessages.Back}
      </Button>
      <br />
      <Card sx={{ padding: 4, marginTop: 2 }}>
        <Grid container spacing={3}>
          <Grid item md={12} xs={12}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item sm={6} xs={12}>
                  <LightTextField
                    fullWidth
                    name="title"
                    placeholder={ApplicationElementsMessages.Title}
                    value={values.title}
                    onChange={handleChange}
                    error={Boolean(touched.title && errors.title)}
                    helperText={touched.title && errors.title}
                  />
                </Grid>

                <Grid item sm={6} xs={12}>
                  <LightTextField
                    fullWidth
                    name="description"
                    placeholder={ApplicationElementsMessages.description}
                    value={values.description}
                    onChange={handleChange}
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                  />
                </Grid>

                <Grid item sm={6} xs={12}>
                  <AppSelect
                    name="roleId"
                    placeholder={ApplicationElementsMessages.Role}
                    data={roleList}
                    onChange={handleChange}
                    defaultValue={id ? values.roleId : "-1"}
                    value={values.roleId}
                  />
                </Grid>

                <Grid item sm={6} xs={12}>
                  <ImagePicker
                    onSelect={(image: string) => {
                      setImageSelected(image);
                    }}
                    value={imageValue}
                  />
                </Grid>

                <Grid item sm={12} xs={12}>
                  <Grid item sm={2} xs={12}>
                    <LoadingButton
                      type="submit"
                      loading={loading}
                      fullWidth
                      variant="contained"
                    >
                      {id
                        ? ApplicationElementsMessages.edit
                        : ApplicationElementsMessages.Submit}
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default AddEditTicketItemsManagement;
