import {SvgIcon, SvgIconProps} from "@mui/material";
import React from "react";

const UserProfile = (props: SvgIconProps) => {
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" {...props}><title>2</title>
            <g id="Layer_26" data-name="Layer 26">
                <path
                    d="M40,26.3A13.49,13.49,0,1,0,24,26.3a25.38,25.38,0,0,0-17.3,24c0,2.72,1.49,6.51,8.6,9.13A50.29,50.29,0,0,0,32,62c12.62,0,25.34-3.61,25.34-11.67A25.38,25.38,0,0,0,40,26.3ZM22.51,15.49A9.49,9.49,0,1,1,32,25,9.5,9.5,0,0,1,22.51,15.49ZM32,58c-12,0-21.34-3.37-21.34-7.67a21.34,21.34,0,1,1,42.69,0C53.34,54.63,44,58,32,58Z"/>
            </g>
        </SvgIcon>
    );
};
export default UserProfile;
