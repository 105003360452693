export class CreateTicketItemDto {
  id: number = 0;
  title: string = "";
  description: string = "";
  icon: string = "";
  roleId: string = "";

  constructor(
    id: number,
    title: string,
    description: string,
    icon: string,
    roleId: string
  ) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.icon = icon;
    this.roleId = roleId;
  }
}
