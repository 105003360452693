import { GlobalConst } from "utils/GlobalConstants";
import { UserManager } from "utils/UserManager";
import { CurrentUserRolePermissionDto } from "../../model/RoleModels/CurrentUserRolePermissionDto";

export class PermissionsHelper {
  userRolePermissions: CurrentUserRolePermissionDto | undefined = undefined;

  constructor(
    userRolePermissions: CurrentUserRolePermissionDto | undefined = undefined
  ) {
    if (userRolePermissions) {
      this.userRolePermissions = userRolePermissions;
      UserManager.get().saveUserRolePermissions(userRolePermissions);
    } else {
      this.userRolePermissions = UserManager.get().loadUserRolePermissions();
    }
  }

  hasPermissions(allowedPermissions: string[]): boolean {
    if (
      !this.userRolePermissions ||
      !this.userRolePermissions.role ||
      !this.userRolePermissions.role.name
    ) {
      return false;
    }

    if (this.userRolePermissions.role.name === GlobalConst.AdminRole) {
      return true;
    }

    return this.userRolePermissions.permissions.some((permission) =>
      allowedPermissions.includes(permission)
    );
  }

  Access = {
    UploadUsersLoanFile: "Users:UploadUsersLoanFile",
    UploadUsersListFile: "Users:UploadUsersListFile",
    UploadMembershipFeeFile: "Users:UploadMembershipFeeFile",
    GetUserMembershipFeeInfo: "Users:GetUserMembershipFeeInfo",
    GetUserLoan: "Users:GetUserLoan",
    ModifyUserInfo: "Users:ModifyUserInfo",
    GetUserInfo: "Users:GetUserInfo",
    ChangeUserStatus: "Users:ChangeUserStatus",
    GetUsers: "Users:GetUsers",

    GetTickets: "Tickets:GetTickets",
    GetTicketMessages: "Tickets:GetTicketMessages",
    ForwardMessage: "Tickets:ForwardMessage",
    AddNewMessage: "Tickets:AddNewMessage",

    GetSettings: "Setting:GetSettings",
    ModifySettings: "Setting:ModifySettings",

    GetLogEntries: "LogEntries:GetLogEntries",

    Transactions: "Transactions:Transactions",

    RequestsLoan: "LoanRequests:GetLoanRequests",
    RequestLoanDetails: "LoanRequests:GetLoanRequestDetail",
    SetLoanRequestStatus: "LoanRequests:SetLoanRequestStatus",

    CurrentLoan: "CurrentLoan:GetCurrentLoan",

    GetReports: "Reports:GetReports",

    InstallmentPayments: "InstallmentPayments:GetInstallmentPayments",

  };
}
