import {Typography} from "@mui/material";
import React from "react";
import {ApplicationElementsMessages} from "../../utils/AppMessages/AppMessages";
import {Box} from "@material-ui/core";

export const LayoutNoData = (props: {
    title?: string
    show?: boolean
}) => {
    return (
        <div hidden={props?.show ? props?.show : false}>
            <Box sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                alignContent: "center",
                width: "100%",
                textAlign: "center"
            }}>
                <Box width={{xs: 1, sm:1, md: 1}}
                     sx={{
                         borderRadius: 10,
                         border: "1px #DEDEDEFF solid",
                         bgcolor: "#fff",
                     }}>
                    <br/>
                    <img src="/static/no_data.jpg" alt="no_data" width="40%"/>
                    <br/>
                    <br/>
                    <Typography variant="h6" component="h2">
                        {props?.title ? props?.title : ApplicationElementsMessages.NoData}
                    </Typography>
                    <br/>
                </Box>
            </Box>
        </div>
    );
};


