export class PagingRequestDto {
  page: number = 1;
  private pageSize: number = 10;

  setPage(page: number) {
    this.page = page;
  }

  nextPage() {
    this.page++;
  }

  previousPage() {
    this.page--;
  }

  get(): string {
    return `&page=${this.page}&pageSize=${this.pageSize}`;
  }
}
