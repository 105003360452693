import {SvgIcon, SvgIconProps} from "@mui/material";
import React from "react";

const Dashboard = (props: SvgIconProps) => {
    return (
        <SvgIcon id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}><title>Navbar,
            Menu</title>
            <path
                d="M8.81,0H2.19A2.19,2.19,0,0,0,0,2.19V8.81A2.19,2.19,0,0,0,2.19,11H8.81A2.19,2.19,0,0,0,11,8.81V2.19A2.19,2.19,0,0,0,8.81,0ZM9,8.81A.18.18,0,0,1,8.81,9H2.19A.18.18,0,0,1,2,8.81V2.19A.18.18,0,0,1,2.19,2H8.81A.18.18,0,0,1,9,2.19Z"/>
            <path
                d="M21.81,0H15.19A2.19,2.19,0,0,0,13,2.19V8.81A2.19,2.19,0,0,0,15.19,11h6.62A2.19,2.19,0,0,0,24,8.81V2.19A2.19,2.19,0,0,0,21.81,0ZM22,8.81a.18.18,0,0,1-.19.19H15.19A.18.18,0,0,1,15,8.81V2.19A.18.18,0,0,1,15.19,2h6.62a.18.18,0,0,1,.19.19Z"/>
            <path
                d="M8.81,13H2.19A2.19,2.19,0,0,0,0,15.19v6.62A2.19,2.19,0,0,0,2.19,24H8.81A2.19,2.19,0,0,0,11,21.81V15.19A2.19,2.19,0,0,0,8.81,13ZM9,21.81a.18.18,0,0,1-.19.19H2.19A.18.18,0,0,1,2,21.81V15.19A.18.18,0,0,1,2.19,15H8.81a.18.18,0,0,1,.19.19Z"/>
            <path
                d="M21.81,13H15.19A2.19,2.19,0,0,0,13,15.19v6.62A2.19,2.19,0,0,0,15.19,24h6.62A2.19,2.19,0,0,0,24,21.81V15.19A2.19,2.19,0,0,0,21.81,13ZM22,21.81a.18.18,0,0,1-.19.19H15.19a.18.18,0,0,1-.19-.19V15.19a.18.18,0,0,1,.19-.19h6.62a.18.18,0,0,1,.19.19Z"/>
        </SvgIcon>
    );
};

export default Dashboard;
