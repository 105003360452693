const addCommas = (num: string) =>
  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

const removeNonNumeric = (num: number) => num.toString().replace(/[^0-9]/g, "");

export const NumberHelper = {
  separateComma(number: number): string {
    if (number) {
      return addCommas(removeNonNumeric(number));
    }

    return "";
  },
  get(number: number | undefined): string {
    if (!number) return `0 ریال`;
    return `${addCommas(removeNonNumeric(number))} ریال `;
  },
};
