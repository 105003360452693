import AccessControl from "components/AccessControl/AccessControl";
import AuthGuard from "components/authentication/AuthGuard";
import GuestGuard from "components/authentication/GuestGuard";
import DashboardLayout from "components/Layouts/DashboardLayout";
import LoadingScreen from "components/LoadingScreen";
import { FC, lazy, LazyExoticComponent, Suspense } from "react";
import { Navigate } from "react-router-dom";
import OperatorList from "./pages/operator/OperatorList";
import AddEditOperator from "./pages/operator/AddEditOperator";
import TicketItemsManagement from "./pages/ticketManagement/TicketItemsManagement";
import AddEditTicketItemsManagement from "./pages/ticketManagement/AddEditTicketItemsManagement";
import DetailUser from "./pages/userManagement/DetailUser";
import {PermissionsHelper} from "./components/AccessControl/PermissionsHelper";
import InstallmentPaymentsList from "./pages/installmentPayments/InstallmentPaymentsList";

const Loadable = (Component: LazyExoticComponent<FC>) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

const Login = Loadable(lazy(() => import("./pages/authentication/Login")));

const DashboardSaaS = Loadable(lazy(() => import("./pages/dashboards/SaaS")));

const LogEntries = Loadable(
  lazy(() => import("./pages/LogEntries/LogEntries"))
);

const Settings = Loadable(lazy(() => import("./pages/Settings")));

const UserList = Loadable(
  lazy(() => import("./pages/userManagement/UserList"))
);

const RoleList = Loadable(
  lazy(() => import("./pages/roleManagement/RoleList"))
);

const AddEditRole = Loadable(
  lazy(() => import("./pages/roleManagement/AddEditRole"))
);

const RolePermissions = Loadable(
  lazy(() => import("./pages/roleManagement/RolePermissions"))
);

const TicketList = Loadable(
  lazy(() => import("./pages/ticketManagement/ticketList/TicketList"))
);

const DetailTicket = Loadable(
  lazy(() => import("./pages/ticketManagement/detailTicket/DetailTicket"))
);

const TransactionList = Loadable(
    lazy(() => import("./pages/transaction/TransactionList"))
);

const RequestLoanList = Loadable(
    lazy(() => import("./pages/requestLoan/RequestLoanList"))
);

const RequestLoanDetails = Loadable(
    lazy(() => import("./pages/requestLoan/RequestLoanDetails"))
);

const CurrentLoanList = Loadable(
    lazy(() => import("./pages/currentLoan/CurrentLoanList"))
);

const Reports = Loadable(
    lazy(() => import("./pages/reports/ReportList"))
);

const Error = Loadable(lazy(() => import("./pages/404")));

const permissions = new PermissionsHelper(undefined)
// routes
const routes = [
  {
    path: "/",
    element: <Navigate to="dashboard" />,
  },
  {
    path: "login",
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    ),
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <DashboardSaaS />,
      },
      {
        path: "user-list",
        element: (
          <AccessControl
            showNoAccess
            allowedPermissions={[permissions.Access.GetUsers]}
          >
            <UserList />
          </AccessControl>
        ),
      },
      {
        path: "detailUser/:id",
        element: (
          <AccessControl showNoAccess>
            <DetailUser />
          </AccessControl>
        ),
      },
      {
        path: "role-list",
        element: (
          <AccessControl showNoAccess>
            <RoleList />
          </AccessControl>
        ),
      },
      {
        path: "operator-list",
        element: (
          <AccessControl showNoAccess>
            <OperatorList />
          </AccessControl>
        ),
      },
      {
        path: "add-edit-operator",
        element: (
          <AccessControl showNoAccess>
            <AddEditOperator />
          </AccessControl>
        ),
      },
      {
        path: "add-edit-operator/:id",
        element: (
          <AccessControl showNoAccess>
            <AddEditOperator />
          </AccessControl>
        ),
      },
      {
        path: "add-edit-role",
        element: (
          <AccessControl showNoAccess>
            <AddEditRole />
          </AccessControl>
        ),
      },
      {
        path: "add-edit-role/:id",
        element: (
          <AccessControl showNoAccess>
            <AddEditRole />
          </AccessControl>
        ),
      },
      {
        path: "rolePermissions/:id",
        element: (
          <AccessControl showNoAccess>
            <RolePermissions />
          </AccessControl>
        ),
      },
      {
        path: "ticketItemsManagement",
        element: (
          <AccessControl showNoAccess>
            <TicketItemsManagement />
          </AccessControl>
        ),
      },
      {
        path: "addEditTicketItems",
        element: (
          <AccessControl showNoAccess>
            <AddEditTicketItemsManagement />
          </AccessControl>
        ),
      },
      {
        path: "addEditTicketItems/:id",
        element: (
          <AccessControl showNoAccess>
            <AddEditTicketItemsManagement />
          </AccessControl>
        ),
      },
      {
        path: "logEntries",
        element: (
          <AccessControl
            showNoAccess
            allowedPermissions={[permissions.Access.GetLogEntries]}
          >
            <LogEntries />
          </AccessControl>
        ),
      },
      {
        path: "settings",
        element: (
          <AccessControl
            showNoAccess
            allowedPermissions={[permissions.Access.GetSettings]}
          >
            <Settings />
          </AccessControl>
        ),
      },
      {
        path: "tickets",
        element: (
          <AccessControl
            showNoAccess
            allowedPermissions={[permissions.Access.GetTickets]}
          >
            <TicketList />
          </AccessControl>
        ),
      },
      {
        path: "detailTicket/:id",
        element: (
          <AccessControl
            showNoAccess
            allowedPermissions={[permissions.Access.GetTicketMessages]}
          >
            <DetailTicket />
          </AccessControl>
        ),
      },
      {
        path: "transactions",
        element: (
            <AccessControl
                showNoAccess
                allowedPermissions={[permissions.Access.Transactions]}
            >
              <TransactionList />
            </AccessControl>
        ),
      },
      {
        path: "requestsLoan",
        element: (
            <AccessControl
                showNoAccess
                allowedPermissions={[permissions.Access.RequestsLoan]}
            >
              <RequestLoanList />
            </AccessControl>
        ),
      },
      {
        path: "currentLoan",
        element: (
            <AccessControl
                showNoAccess
                allowedPermissions={[permissions.Access.CurrentLoan]}
            >
              <CurrentLoanList />
            </AccessControl>
        ),
      },
      {
        path: "requestLoanDetails/:id",
        element: (
            <AccessControl
                showNoAccess
                allowedPermissions={[permissions.Access.RequestLoanDetails]}
            >
              <RequestLoanDetails />
            </AccessControl>
        ),
      },
      {
        path: "installmentPayments",
        element: (
            <AccessControl
                showNoAccess
                allowedPermissions={[permissions.Access.InstallmentPayments]}
            >
              <InstallmentPaymentsList />
            </AccessControl>
        ),
      },
      {
        path: "reports",
        element: (
            <AccessControl
                showNoAccess
                allowedPermissions={[permissions.Access.GetReports]}
            >
              <Reports/>
            </AccessControl>
        ),
      },
    ],
  },
  {
    path: "*",
    element: <Error />,
  },
];

export default routes;
