import { ApplicationElementsMessages } from "utils/AppMessages/AppMessages";

export enum EmploymentType {
  Official = 1,
  Peymani = 2,
  Contractual = 3,
  Other = 4,
}

export const EmploymentTypeLabel = new Map<EmploymentType, string>([
  [EmploymentType.Official, "رسمی"],
  [EmploymentType.Peymani, "پیمانی"],
  [EmploymentType.Contractual, "قراردادی"],
  [EmploymentType.Other, "سایر"],
]);

export const EmploymentTypeList = [
  { id: 0, value: ApplicationElementsMessages.Choose },
  {
    id: EmploymentType.Official,
    value: EmploymentTypeLabel.get(EmploymentType.Official),
  },
  {
    id: EmploymentType.Peymani,
    value: EmploymentTypeLabel.get(EmploymentType.Peymani),
  },
  {
    id: EmploymentType.Contractual,
    value: EmploymentTypeLabel.get(EmploymentType.Contractual),
  },
  {
    id: EmploymentType.Other,
    value: EmploymentTypeLabel.get(EmploymentType.Other),
  },
];
