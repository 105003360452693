import { ApplicationElementsMessages } from "utils/AppMessages/AppMessages";
import { PermissionsHelper } from "./PermissionsHelper";

interface IAccessControlProps {
  allowedPermissions?: string[];
  children: any;
  showNoAccess?: boolean;
}

const AccessControl = (props: IAccessControlProps) => {
  const permissions = new PermissionsHelper();
  const hasAccess = permissions.hasPermissions(
    props.allowedPermissions ? props.allowedPermissions : []
  );

  if (hasAccess) {
    return props.children;
  }

  if (props.showNoAccess) {
    return <div>{ApplicationElementsMessages.AccessDenied}</div>;
  }

  return null;
};

export default AccessControl;
