import axios from "axios";
import { UserManager } from "../utils/UserManager";
import { baseUrl } from "./HttpConstants";
import IApiResultData from "./model/IApiResultData";
import JwtToken from "./model/JwtToken";
import Remote from "./Remote";
import ApiService from "./ApiService";

const instance = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    console.log(config.url);
    const token = UserManager.get().loadToken().token;
    if (token) {
      config.headers!.Authorization = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig.url !== "/client/login" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        Remote.call(
          ApiService.refreshToken(UserManager.get().loadToken()),
          (response: IApiResultData<JwtToken>) => {
            if (response.data) {
              UserManager.get().saveToken(response.data);
            } else {
              UserManager.get().deleteToken();
              window.location.href = "/login";
            }
          },
          (onProgress: boolean) => {},
          (error: string) => {
            UserManager.get().deleteToken();
            window.location.href = "/login";
          }
        );
        return instance(originalConfig);
      }
    }
    return Promise.reject(err);
  }
);
export default instance;
