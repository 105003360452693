import { Grid, NativeSelectProps } from "@material-ui/core";
import { NativeSelect, styled } from "@mui/material";
import { NativeSelectInputProps } from "@mui/material/NativeSelect/NativeSelectInput";

const AppSelect = (props: {
  name?: string;
  placeholder?: string;
  dir?: string;
  data?: any;
  size?: Number;
  disable?: boolean;
  hidden?: boolean;
  defaultValue?: unknown;
  value?: unknown;
  onChange?: NativeSelectInputProps["onChange"];
}) => {
  if (props?.data === undefined) return <div />;
  let list = props?.data ? props.data : [];

  let listItem = [];
  for (let index = 0; index < list.length; index++) {
    const element = list[index];
    if (element?.id !== undefined) {
      listItem.push(
        <option key={element.id} value={element.id}>
          {element.value}
        </option>
      );
    }
  }

  let getDefaultValue = 0;
  if (list.length > 0) getDefaultValue = list.at(0).id;

  return (
    <div hidden={props.hidden}>
      <Grid item xs={12} md={12}>
        <LightSelect
          fullWidth
          disableUnderline
          id={props.name}
          name={props.name}
          dir={props.dir ? props.dir : "rtl"}
          defaultValue={
            props.defaultValue ? props.defaultValue : getDefaultValue
          }
          onChange={props.onChange}
          variant="outlined"
          value={props.value}
          placeholder={props.placeholder}
        >
          {listItem}
        </LightSelect>
      </Grid>
    </div>
  );
};

const StyledSelect = styled(NativeSelect)<NativeSelectProps>(({ theme }) => ({
  "& .MuiNativeSelect-nativeInput": {
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  "& .MuiNativeSelect-outlined": {
    borderRadius: "8px",
    border: "2px solid",
    padding: "15px",
    borderColor:
      theme.palette.mode === "light"
        ? theme.palette.secondary[300]
        : theme.palette.divider,
  },
  "& .Mui-disabled .MuiNativeSelect-outlined": {
    borderColor: theme.palette.secondary[300],
  },
}));

const LightSelect = (props: any) => {
  return <StyledSelect {...props} />;
};

export default AppSelect;
