import { ApplicationElementsMessages } from "utils/AppMessages/AppMessages";

export enum LoanRequestPaymentStatus {
  Pending = 1,
  Confirmed,
  Rejected,
}

export const LoanRequestPaymentStatusLabel = new Map<
  LoanRequestPaymentStatus,
  string
>([
  [LoanRequestPaymentStatus.Pending, "در انتظار تایید"],
  [LoanRequestPaymentStatus.Confirmed, "تایید"],
  [LoanRequestPaymentStatus.Rejected, "عدم تایید"],
]);

export const LoanRequestPaymentStatusList = [
  { id: 0, value: ApplicationElementsMessages.Choose },
  {
    id: LoanRequestPaymentStatus.Pending,
    value: LoanRequestPaymentStatusLabel.get(LoanRequestPaymentStatus.Pending),
  },
  {
    id: LoanRequestPaymentStatus.Confirmed,
    value: LoanRequestPaymentStatusLabel.get(
      LoanRequestPaymentStatus.Confirmed
    ),
  },
  {
    id: LoanRequestPaymentStatus.Rejected,
    value: LoanRequestPaymentStatusLabel.get(LoanRequestPaymentStatus.Rejected),
  },
];
