import RequestLoanList from "../../pages/requestLoan/RequestLoanList";
import InstallmentPaymentsList from "../../pages/installmentPayments/InstallmentPaymentsList";

export const ErrorMessages = {
  TitleRequired: "عنوان الزامی است",
  DescriptionRequired: "توضیحات الزامی است",
  IncorrectPhoneNumber: "شماره موبایل صحیح نمیباشد",
  RequiredConfirmCode: "کد تایید را وارد کنید",
  InvalidName: "نام را صحیح وارد نمایید",
  InvalidUsername: "نام کاربری را صحیح وارد نمایید",
  InvalidPassword: "رمز عبور را صحیح وارد نمایید",
  InvalidFamily: "نام خانوادگی را صحیح وارد نمایید",
  InvalidConfirmCodeLength: "تعداد ارقام کد صحیح نمیباشد",
  ErrorOcurred: "خطایی رخ داده است",
  ErrorConnection: "خطا در برقراری ارتباط با سرور",
  NotFound404: "خطای 404 ، موردی یافت نشد",
  ErrorNotFound: "خطا در اطلاعات دریافتی، سعی مجدد",
  ErrorRefreshToken: "خطا در احراز هویت، سعی مجدد",
  RequiredMessageText: "متن پیام الزامی است",
  RequiredFileSelection: "انتخاب فایل الزامی است",
};

export const InformationMessages = {
  Wait: "صبر کنید",
  SuccessfulOperations: "عملیات با موفقیت انجام شد",
};

export const ApplicationElementsMessages = {
  AppName: "پنل مدیریت صندوق اعتماد",
  Name: "نام",
  Family: "نام خانوادگی",
  FatherName: "نام پدر",
  NationalCode: "کد ملی",
  EnterCode: "کد را وارد نمایید",
  Confirm: "تایید",
  NotConfirm: "عدم تایید",
  Cancel: "انصراف",
  Back: "بازگشت",
  Send: "ارسال",
  Resend: "ارسال مجدد",
  Home: "خانه",
  Title: "عنوان",
  Mobile: "موبایل",
  TryAgain: "سعی مجدد",
  Exit: "خروج",
  Username: "نام کاربری",
  Password: "رمز عبور",
  Submit: "ثبت",
  ConfirmPassword: "تکرار رمز عبور",
  maxLoanAmount: "حداکثر مبلغ وام",
  minLoanAmount: "حداقل مبلغ وام",
  Account: "حساب کاربری",
  NoMoreData: "اطلاعات دیگری موجود نیست",
  NoData: "موردی یافت نشد",
  LoadingData: "در حال بارگذاری ... ",
  ErrorLoadingData: "خطا هنگام بارگذاری",
  SmsRemain: "موجودی پنل پیامکی",
  Login: "ورود",
  HomePhoneNumber: "شماره تلفن منزل",
  WorkPhoneNumber: "شماره تلفن محل کار",
  Logout: "خروج",
  LoginToPanel: "ورود به پنل مدیریت صندوق اعتماد",
  LogEntries: "خطاهای سیستمی",
  Users: "کاربران",
  edit: "ویرایش",
  Settings: "تنظیمات",
  Dashboard: "داشبورد",
  Operators: "اپراتورها",
  DefineOperator: "تعریف اپراتور",
  DefineRole: "تعریف نقش",
  EditRole: "ویرایش نقش",
  Roles: "نقش ها",
  Role: "نقش",
  BirthCertificateId: "شماره شناسنامه",
  Email: "ایمیل",
  LegalCode: "کد حقوقی",
  AccountNumber: "شماره حساب",
  RoleManagement: "مدیریت دسترسی",
  CommunicationBox: "صندوق ارتباطی",
  PensionFundType: "نوع صندوق بازنشستگی",
  EmploymentType: "نوع استخدام",
  JobType: "نوع شغل",
  JobLocation: "محل شغل",
  CommunicationBoxMessages: "پیام ها",
  ManageCommunicationBox: "مدیریت افراد",
  UserSearchHint: "نام ، موبایل ، کدملی",
  Search: "جستجو",
  EnglishTitle: "عنوان انگلیسی",
  DisplayName: "نام نمایشی",
  UserListFileUpload: "بارگذاری فایل اعضا",
  MembershipFileUpload: "بارگذاری فایل حق عضویت",
  LoanFileUpload: "بارگذاری فایل لیست وام",
  Upload: "بارگذاری",
  AccessDenied: "دسترسی غیر مجاز",
  Accept: "تایید",
  Permissions: "دسترسی ها",
  addOperator: "افزودن اپراتور",
  messageDeleteItem: "این آیتم حذف شود؟",
  delete: "حذف",
  add: "افزودن",
  description: "توضیحات",
  defineTicketItems: "تعریف کاربر صندوق",
  Enable: "فعال کردن",
  Disable: "غیر فعال کردن",
  MembershipInfo: "اطلاعات حق عضویت",
  EditInfo: "ویرایش مشخصات",
  NotConfirmed: "تایید نشده",
  LoanInfo: "اطلاعات وام",
  CurrentLoanInfo: "اطلاعات وام جاری",
  Choose: "انتخاب نمایید",
  ChooseRole: "انتخاب نقش",
  details: "جزییات",
  userInfo: "اطلاعات کاربر",
  id: "شناسه",
  amount: "مبلغ",
  transactionType: "نوع تراکنش",
  transactionStatus: "وضعیت تراکنش",
  transactions: "تراکنش ها",
  hintTransactionSearch: "نام، موبایل، کدملی ...",
  type: "نوع",
  all: "همه",
  active: "فعال",
  deActive: "غیر فعال",
  notAccept: "تایید نشده",
  profileCompleted: "پروفایل تکمیل شده",
  accept: "تایید",
  requestLoan: "درخواستهای وام",
  currentLoan: "وام های جاری",
  requestedMaxLoan: "درخواست سقف وام",
  differenceMaxLoanAmount: " مبلغ مابه التفاوت",
  status: "وضعیت",
  dataUserRequestLoan: "اطلاعات درخواست دهنده وام",
  dataGuarantor: "اطلاعات ضامن",
  changeStatusRequestLoan: "تغییر وضعیت درخواست وام",
  update: "بروزرسانی",
  currentStatus: "وضعیت جاری",
  loanAmount: "مبلغ وام",
  reports: "گزارشات",
  report: "گزارش",
  date: "تاریخ",
  membershipFeeGroupMain: "گروه اصلی",
  membershipFeeGroupNotMain: "گروه فرعی",
  submitMembershipFeeGroup: "ثبت حق عضویت گروهی",
  remaining: "باقیمانده",
  installmentPayments: "پرداخت اقساط",
  receipt:"رسید",
  reject:"عدم تایید",
  loan: "وام",
};
