import React from "react";
import LoadingSpinner from "./Spinner/LoadingSpinner";

interface AppContainerProps {
  children: any;
  isLoadingSpinner: boolean;
}

export const AppContainer: React.FC<AppContainerProps> = ({
  children,
  isLoadingSpinner,
}) => {
  return (
    <>
      <div hidden={isLoadingSpinner}>{children}</div>
      {isLoadingSpinner ? <LoadingSpinner /> : null}
    </>
  );
};

export default AppContainer;
