import { Card, Grid, styled, useTheme } from "@mui/material";
import useTitle from "hooks/useTitle";
import { FC, useEffect, useState } from "react";
import Remote from "../../remote/Remote";
import ApiService from "../../remote/ApiService";
import IApiResultData from "../../remote/model/IApiResultData";
import toast from "react-hot-toast";
import { UserDto } from "../../model/UserDto";
import { ApplicationElementsMessages } from "utils/AppMessages/AppMessages";
import AppContainer from "components/core/AppContainer";
import IApiResult from "remote/model/IApiResult";
import LightTextField from "components/LightTextField";
import { H2, H3, H5 } from "components/Typography";
import { SelectGroupModel } from "model/SelectGroupDto";
import {
  PensionFundType,
  PensionFundTypeList,
} from "model/Enums/PensionFundType";
import { EmploymentType, EmploymentTypeList } from "model/Enums/EmploymentType";
import { JobType, JobTypeList } from "model/Enums/JobType";
import AppSelect from "components/core/Select/App-Select";
import AppSelectGroup from "components/core/Select/App-Select-Group";
import { useNavigate, useParams } from "react-router-dom";
import { Box } from "@material-ui/core";
import { LoadingButton } from "@mui/lab";
import DetailUserLoan from "./DetailUserLoan";
import { NumberHelper } from "utils/NumberHelper";
import LoanDto from "model/LoanDto";
import {
  MembershipFeeGroupType,
  MembershipFeeGroupTypeList,
} from "model/Enums/MembershipFeeGroupType";

const StyledCard = styled(Card)(({ theme }) => ({
  marginTop: "10px",
  padding: "15px 40px 40px 40px",
  alignItems: "center",
  width: "100%",
  height: "100%",
  [theme.breakpoints.down("sm")]: {
    padding: "15px 40px 20px 40px",
    flexDirection: "column",
    justifyContent: "center",
    "& .MuiBox-root": {
      marginRight: 0,
      textAlign: "center",
    },
  },
}));

const DetailUser: FC = () => {
  useTitle(ApplicationElementsMessages.details);
  const theme = useTheme();
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [loan, setLoan] = useState<LoanDto>();

  const [mobile, setMobile] = useState<string>("");
  const [nationalCode, setNationalCode] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [family, setFamily] = useState<string>("");
  const [legalCode, setLegalCode] = useState<string>("");
  const [fatherName, setFatherName] = useState<string>("");
  const [birthCertificateId, setBirthCertificateId] = useState<string>("");
  const [phoneNumberHome, setPhoneNumberHome] = useState<string>("");
  const [phoneNumberWork, setPhoneNumberWork] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [accountNumber, setAccountNumber] = useState<string>("");
  const [jobLocationList, setJobLocationList] = useState<SelectGroupModel[]>(
    []
  );

  const [membershipFeeGroupType, setMembershipFeeGroupType] =
    useState<MembershipFeeGroupType | null>(null);

  const [pensionFundType, setPensionFundType] = useState<PensionFundType>();
  const [employmentType, setEmploymentType] = useState<EmploymentType>();
  const [jobType, setJobType] = useState<JobType>();
  const [jobLocation, setJobLocation] = useState<number>(0);

  const [membershipFee, setMembershipFee] = useState<number>(0);

  //show
  const [showJobLocation, setShowJobLocation] = useState<boolean>(false);

  //Status
  const [userStatus, setUserStatus] = useState<boolean>(true);
  const [userStatusText, setUserStatusText] = useState<string>("");

  useEffect(() => {
    getUserInfo();
    getJobLocationList();
  }, []);

  useEffect(() => {
    setUserStatusText(
      userStatus
        ? ApplicationElementsMessages.Disable
        : ApplicationElementsMessages.Enable
    );
  }, [userStatus]);

  const getUserInfo = () => {
    if (!id) {
      navigate("/dashboard/user-list");
      return;
    }

    Remote.call(
      ApiService.getUserInfo(id!),
      (response: IApiResultData<UserDto>) => {
        if (response.data) {
          setUserInfo(response.data);
          getUserLoan();
        } else {
          toast.error(response.message);
          navigate("/dashboard/user-list");
        }
      },
      (onProgress: boolean) => {
        setLoading(onProgress);
      },
      (error: string) => {
        toast.error(error);
      }
    );
  };

  const getUserLoan = () => {
    Remote.call(
      ApiService.getUserLoan(id!),
      (response: IApiResultData<LoanDto>) => {
        if (response.data) setLoan(response.data);
      },
      (onProgress: boolean) => {
        setLoading(onProgress);
      },
      (error: string) => {
        toast.error(error);
      }
    );
  };

  const setUserInfo = (data: UserDto) => {
    setMembershipFee(data.membershipFee!);
    setNationalCode(data.nationalCode!);
    setMobile(data.mobile!);
    setName(data.name!);
    setFamily(data.family!);
    setLegalCode(data.legalCode!);
    setFatherName(data.fatherName!);
    setBirthCertificateId(data.birthCertificateId!);
    setPhoneNumberHome(data.homePhoneNumber!);
    setPhoneNumberWork(data.workPhoneNumber!);
    setAccountNumber(data.accountNumber!);
    setEmail(data.email!);
    setPensionFundType(data.pensionFundType!);
    setEmploymentType(data.employmentType!);
    setMembershipFeeGroupType(data.membershipFeeGroupType!);
    setJobType(data.jobType!);
    setJobLocation(data.jobLocation!);
    if (data.jobType === JobType.Employed) {
      setShowJobLocation(true);
    }

    setUserStatus(data.enabled!);
  };

  const modifyUserInfo = () => {
    const editedData: UserDto = {
      id: id,
      nationalCode: nationalCode,
      mobile: mobile,
      name: name,
      family: family,
      legalCode: legalCode,
      fatherName: fatherName,
      birthCertificateId: birthCertificateId,
      homePhoneNumber: phoneNumberHome,
      workPhoneNumber: phoneNumberWork,
      accountNumber: accountNumber,
      email: email,
      pensionFundType: pensionFundType as number,
      employmentType: employmentType as number,
      jobType: jobType as number,
      jobLocation: jobLocation,
      membershipFeeGroupType: membershipFeeGroupType
        ? membershipFeeGroupType
        : null,
    };

    Remote.call(
      ApiService.modifyUserInfo(editedData),
      (response: IApiResult) => {
        if (response.isSuccess) {
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      },
      (onProgress: boolean) => {
        setLoading(onProgress);
      },
      (error: string) => {
        toast.error(error);
      }
    );
  };

  const changeUserStatus = () => {
    const newStatus = userStatus ? false : true;
    Remote.call(
      ApiService.changeUserStatus({
        userId: id,
        enableStatus: newStatus,
      }),
      (response: IApiResult) => {
        if (response.isSuccess) {
          setUserStatus(newStatus);
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      },
      (onProgress: boolean) => {
        setLoading(onProgress);
      },
      (error: string) => {
        toast.error(error);
      }
    );
  };

  const getJobLocationList = () => {
    Remote.call(
      ApiService.getJobLocationList(),
      (response: IApiResultData<SelectGroupModel[]>) => {
        if (response.data) {
          setJobLocationList(response.data);
        }
      },
      (onProgress: boolean) => {
        setLoading(onProgress);
      },
      (error: string) => {
        toast.error(error);
      }
    );
  };

  const mobileChange = (event: any): void => {
    setMobile(event.target.value);
  };

  const nationalCodeChange = (event: any): void => {
    setNationalCode(event.target.value);
  };
  const nameChange = (event: any): void => {
    setName(event.target.value);
  };
  const familyChange = (event: any): void => {
    setFamily(event.target.value);
  };
  const legalCodeChange = (event: any): void => {
    setLegalCode(event.target.value);
  };
  const fatherNameChange = (event: any): void => {
    setFatherName(event.target.value);
  };
  const birthCertificateIdChange = (event: any): void => {
    setBirthCertificateId(event.target.value);
  };

  const phoneNumberHomeChange = (event: any): void => {
    setPhoneNumberHome(event.target.value);
  };
  const phoneNumberWorkChange = (event: any): void => {
    setPhoneNumberWork(event.target.value);
  };
  const emailChange = (event: any): void => {
    setEmail(event.target.value);
  };

  const accountNumberChange = (event: any): void => {
    setAccountNumber(event.target.value);
  };

  const pensionFundTypeChange = (event: any): void => {
    const value = parseInt(event.target.value);
    setPensionFundType(value);
  };

  const membershipFeeGroupTypeChange = (event: any): void => {
    const value = parseInt(event.target.value);

    if (value !== MembershipFeeGroupTypeList[0].id) {
      setMembershipFeeGroupType(value);
    } else {
      setMembershipFeeGroupType(null);
    }
  };

  const employmentTypeChange = (event: any): void => {
    const value = parseInt(event.target.value);
    setEmploymentType(value);
  };

  const jobTypeChange = (event: any): void => {
    const value = parseInt(event.target.value);
    setJobType(value);
    if (value === JobType.Employed) {
      setShowJobLocation(true);
    } else {
      setShowJobLocation(false);
      setJobLocation(0);
    }
  };

  const jobLocationChange = (event: any): void => {
    setJobLocation(event.target.value);
  };

  return (
    <AppContainer isLoadingSpinner={loading}>
      <Box pb={10}>
        {loan && (
          <StyledCard>
            <H3
              paddingBottom="30px"
              style={{ width: "100%", textAlign: "right", color: "#2499EF" }}
            >
              {ApplicationElementsMessages.CurrentLoanInfo}
            </H3>
            <DetailUserLoan loanInfo={loan} />
          </StyledCard>
        )}

        <StyledCard>
          <H3
            paddingBottom="30px"
            style={{ width: "100%", textAlign: "right", color: "#2499EF" }}
          >
            {ApplicationElementsMessages.userInfo}
          </H3>

          <H5
            paddingBottom="30px"
            style={{ width: "100%", textAlign: "right", color: "#2499EF" }}
          >
            موجودی حق عضویت : {NumberHelper.separateComma(membershipFee)}
          </H5>

          <Grid container spacing={{ xs: 3, sm: 3, md: 3 }}>
            <Grid item md={4} xs={12}>
              <H2 fontSize={15} fontWeight={700}>
                {ApplicationElementsMessages.NationalCode}
              </H2>
              <LightTextField
                value={nationalCode}
                onChange={nationalCodeChange}
                type="number"
                dir="ltr"
                fullWidth
                name="nationalCode"
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <H2 fontSize={15} fontWeight={700}>
                {ApplicationElementsMessages.Mobile}
              </H2>
              <LightTextField
                value={mobile}
                onChange={mobileChange}
                type="text"
                dir="ltr"
                fullWidth
                name="mobile"
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <H2 fontSize={15} fontWeight={700}>
                {ApplicationElementsMessages.Name}
              </H2>
              <LightTextField
                value={name}
                onChange={nameChange}
                type="text"
                dir="rtl"
                fullWidth
                name="name"
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <H2 fontSize={15} fontWeight={700}>
                {ApplicationElementsMessages.Family}
              </H2>
              <LightTextField
                value={family}
                onChange={familyChange}
                type="text"
                dir="rtl"
                fullWidth
                name="family"
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <H2 fontSize={15} fontWeight={700}>
                {ApplicationElementsMessages.FatherName}
              </H2>
              <LightTextField
                value={fatherName}
                onChange={fatherNameChange}
                type="text"
                dir="rtl"
                fullWidth
                name="fatherName"
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <H2 fontSize={15} fontWeight={700}>
                {ApplicationElementsMessages.HomePhoneNumber}
              </H2>
              <LightTextField
                value={phoneNumberHome}
                onChange={phoneNumberHomeChange}
                type="text"
                dir="ltr"
                fullWidth
                name="homePhoneNumber"
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <H2 fontSize={15} fontWeight={700}>
                {ApplicationElementsMessages.WorkPhoneNumber}
              </H2>
              <LightTextField
                value={phoneNumberWork}
                onChange={phoneNumberWorkChange}
                type="text"
                dir="ltr"
                fullWidth
                name="workPhoneNumber"
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <H2 fontSize={15} fontWeight={700}>
                {ApplicationElementsMessages.BirthCertificateId}
              </H2>
              <LightTextField
                value={birthCertificateId}
                onChange={birthCertificateIdChange}
                type="text"
                dir="ltr"
                fullWidth
                name="birthCertificateId"
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <H2 fontSize={15} fontWeight={700}>
                {ApplicationElementsMessages.Email}
              </H2>
              <LightTextField
                value={email}
                onChange={emailChange}
                type="text"
                dir="ltr"
                fullWidth
                name="email"
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <H2 fontSize={15} fontWeight={700}>
                {ApplicationElementsMessages.LegalCode}
              </H2>
              <LightTextField
                value={legalCode}
                onChange={legalCodeChange}
                type="text"
                dir="ltr"
                fullWidth
                name="legalCode"
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <H2 fontSize={15} fontWeight={700}>
                {ApplicationElementsMessages.AccountNumber}
              </H2>
              <LightTextField
                value={accountNumber}
                onChange={accountNumberChange}
                type="text"
                dir="ltr"
                fullWidth
                name="accountNumber"
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <H2 fontSize={15} fontWeight={700}>
                گروه حق عضویت
              </H2>
              <AppSelect
                data={MembershipFeeGroupTypeList}
                value={membershipFeeGroupType}
                onChange={membershipFeeGroupTypeChange}
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <H2 fontSize={15} fontWeight={700}>
                {ApplicationElementsMessages.PensionFundType}
              </H2>
              <AppSelect
                data={PensionFundTypeList}
                value={pensionFundType}
                onChange={pensionFundTypeChange}
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <H2 fontSize={15} fontWeight={700}>
                {ApplicationElementsMessages.EmploymentType}
              </H2>
              <AppSelect
                data={EmploymentTypeList}
                value={employmentType}
                onChange={employmentTypeChange}
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <H2 fontSize={15} fontWeight={700}>
                {ApplicationElementsMessages.JobType}
              </H2>
              <AppSelect
                data={JobTypeList}
                value={jobType}
                onChange={jobTypeChange}
              />
            </Grid>

            {showJobLocation ? (
              <Grid item md={4} xs={12}>
                <H2 fontSize={15} fontWeight={700}>
                  {ApplicationElementsMessages.JobLocation}
                </H2>
                <AppSelectGroup
                  hidden={!showJobLocation}
                  data={jobLocationList}
                  defaultValue={jobLocation}
                  onChange={jobLocationChange}
                />
              </Grid>
            ) : null}

            <Grid item md={12} xs={12}>
              <LoadingButton
                type="submit"
                loading={loading}
                variant="contained"
                style={{
                  marginTop: 30,
                  marginBottom: 10,
                  marginRight: 15,
                  marginLeft: 15,
                }}
                onClick={() => {
                  modifyUserInfo();
                }}
              >
                {ApplicationElementsMessages.edit}
              </LoadingButton>

              <LoadingButton
                type="submit"
                loading={loading}
                variant="contained"
                style={{
                  backgroundColor: !userStatus
                    ? theme.palette.success.main
                    : theme.palette.primary.red,
                  marginTop: 30,
                  marginBottom: 10,
                  marginRight: 15,
                  marginLeft: 15,
                }}
                onClick={() => {
                  changeUserStatus();
                }}
              >
                {userStatusText}
              </LoadingButton>
            </Grid>
          </Grid>
        </StyledCard>
      </Box>
    </AppContainer>
  );
};

export default DetailUser;
