import { ApplicationElementsMessages } from "utils/AppMessages/AppMessages";
import { H6 } from "../../components/Typography";
import { NumberHelper } from "../../utils/NumberHelper";
import { InstallmentPaymentsListDto } from "../../model/installmentPayments/InstallmentPaymentsListDto";
import {
  LoanRequestPaymentStatus,
  LoanRequestPaymentStatusLabel,
} from "../../model/Enums/LoanRequestPaymentStatus";
import React from "react";
import { Button } from "@mui/material";
import FlexBox from "../../components/FlexBox";

const ColumnInstallmentPayments = [
  {
    Header: ApplicationElementsMessages.id,
    accessor: "id",
    minWidth: 30,
  },
  {
    Header:
      ApplicationElementsMessages.Name +
      "/" +
      ApplicationElementsMessages.Family,
    accessor: "name",
    minWidth: 80,
    Cell: ({ row }: any) => {
      const { user }: InstallmentPaymentsListDto = row.original;
      return <H6>{user?.name + " " + user?.family}</H6>;
    },
  },
  {
    Header: ApplicationElementsMessages.date,
    accessor: "date",
    minWidth: 80,
  },
  {
    Header: ApplicationElementsMessages.LoanInfo,
    accessor: "showLoan",
    minWidth: 80,
    Cell: ({ row }: any) => {
      return (
        <div>
          <Button name="details" variant="outlined">
            {ApplicationElementsMessages.loan}
          </Button>
        </div>
      );
    },
  },
  {
    Header: ApplicationElementsMessages.description,
    accessor: "description",
    minWidth: 100,
  },
  {
    Header: ApplicationElementsMessages.amount,
    accessor: "amount",
    minWidth: 80,
    Cell: ({ row }: any) => {
      const { amount }: InstallmentPaymentsListDto = row.original;
      return <H6>{NumberHelper.get(amount)}</H6>;
    },
  },
  {
    Header: ApplicationElementsMessages.status,
    accessor: "status",
    minWidth: 100,
    Cell: ({ row }: any) => {
      const { status }: InstallmentPaymentsListDto = row.original;
      const { id }: InstallmentPaymentsListDto = row.original;
      return (
        <div>
          <div hidden={status == LoanRequestPaymentStatus.Pending}>
            <H6
              style={{
                color:
                  status == LoanRequestPaymentStatus.Confirmed
                    ? "green"
                    : "red",
              }}
            >
              {LoanRequestPaymentStatusLabel.get(
                status as LoanRequestPaymentStatus
              )}
            </H6>
          </div>
          <div hidden={status != LoanRequestPaymentStatus.Pending}>
            <FlexBox flexDirection="column">
              <Button name="confirmed" variant="contained">
                {ApplicationElementsMessages.accept}
              </Button>
              <br />
              <Button
                name="rejected"
                variant="contained"
                color="error"
                style={{ color: "white" }}
              >
                {ApplicationElementsMessages.reject}
              </Button>
            </FlexBox>
          </div>
        </div>
      );
    },
  },
  {
    Header: ApplicationElementsMessages.receipt,
    accessor: "fileUrl",
    minWidth: 150,
    Cell: ({ row }: any) => {
      const { fileUrl }: InstallmentPaymentsListDto = row.original;
      return (
        <div>
          <a href={fileUrl} target="_blank">
            <img src={fileUrl} width="120px" />
          </a>
        </div>
      );
    },
  },
];

export default ColumnInstallmentPayments;
