import { Box, Button, styled } from "@mui/material";
import FlexBox from "components/FlexBox";
import SearchInput from "components/SearchInput";
import CustomTable from "components/userManagement/CustomTable";
import useTitle from "hooks/useTitle";
import React, { ChangeEvent, FC, useEffect, useState } from "react";
import Remote from "../../remote/Remote";
import ApiService from "../../remote/ApiService";
import IApiResultData from "../../remote/model/IApiResultData";
import toast from "react-hot-toast";
import { ApplicationElementsMessages } from "utils/AppMessages/AppMessages";
import AppContainer from "components/core/AppContainer";
import { PagingRequestDto } from "../../model/paging/PagingRequestDto";
import IPagedResultData from "remote/model/IPagedResultData";
import columnOperator from "./columnOperator";
import { useNavigate } from "react-router-dom";
import { OperatorDto } from "../../model/RoleModels/OperatorDto";
import IApiResult from "../../remote/model/IApiResult";
import { LayoutNoData } from "../../components/core/LayoutNoData";
import ModalMessage from "../../components/core/modal/ModalMessage";

// styled component
const StyledFlexBox = styled(FlexBox)(({ theme }) => ({
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  marginBottom: 20,
  [theme.breakpoints.down(500)]: {
    width: "100%",
    "& .MuiInputBase-root": { maxWidth: "100%" },
    "& .MuiButton-root": {
      width: "100%",
      marginTop: 15,
    },
  },
}));

const OperatorList: FC = () => {
  useTitle(ApplicationElementsMessages.Operators);
  const navigate = useNavigate();
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [operatorList, setOperatorList] = useState<OperatorDto[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [deleteOperatorId, setDeleteOperatorId] = useState<string>("");
  let paging = new PagingRequestDto();

  useEffect(() => {
    if (operatorList.length === 0) getOperators();
  }, []);

  const getOperators = () => {
    Remote.call(
      ApiService.getOperators(search, paging.get()),
      (response: IApiResultData<IPagedResultData<OperatorDto>>) => {
        if (response.data.items) {
          setOperatorList(response.data.items);
          setTotalPages(response.data.totalPages);
          setCurrentPage(response.data.currentPage);
        }
      },
      (onProgress: boolean) => {
        setLoading(onProgress);
      },
      (error: string) => {
        toast.error(error);
      }
    );
  };

  const onChangePage = (_e: ChangeEvent<unknown>, page: number) => {
    setPage(page);
    getOperators();
  };

  const onClick = (id: any, operator: OperatorDto) => {
    if (id === "edit") {
      if (operator && operator.id)
        navigate(`/dashboard/add-edit-operator/${operator.id}`);
    } else if (id === "deActive") {
      setDeleteOperatorId(operator.id);
      setShowMessage(true);
    }
  };

  const onClickSearch = () => {
    setPage(1);
    getOperators();
  };

  const onClickAddOperator = () => {
    navigate(`/dashboard/add-edit-operator`);
  };

  const deleteOperator = () => {
    Remote.call(
      ApiService.deleteOperator(deleteOperatorId),
      (response: IApiResult) => {
        if (response.isSuccess) {
          toast.success(response.message);
          getOperators();
        } else {
          toast.error(response.message);
        }
      },
      (onProgress: boolean) => {
        setLoading(onProgress);
      },
      (error: string) => {
        toast.error(error);
      }
    );
  };

  const onResultModal = (res: any) => {
    if (res === true) deleteOperator();
  };

  const setPage = (page: number) => {
    paging.setPage(page);
    setCurrentPage(page);
  };

  return (
    <AppContainer isLoadingSpinner={loading}>
      <Box pt={2} pb={4}>
        <StyledFlexBox>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <SearchInput
              placeholder={ApplicationElementsMessages.Username}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setSearch(event.target.value);
              }}
            />
            <Box mr={1} ml={1} />
            <Button
              style={{ marginRight: 5 }}
              variant="contained"
              onClick={onClickSearch}
            >
              {ApplicationElementsMessages.Search}
            </Button>
          </Box>
          <div style={{ marginTop: 5 }}>
            <Button
              style={{ margin: 5 }}
              variant="contained"
              onClick={onClickAddOperator}
            >
              {ApplicationElementsMessages.addOperator}
            </Button>
          </div>
        </StyledFlexBox>

        <div hidden={operatorList.length === 0}>
          <CustomTable
            columnShape={columnOperator}
            data={operatorList}
            totalPage={totalPages}
            currentPage={currentPage}
            onChangePage={onChangePage}
            rowClick={onClick}
          />
        </div>
        <LayoutNoData show={operatorList.length !== 0} />
      </Box>
    </AppContainer>
  );
};

export default OperatorList;
