import { Box, Button, styled } from "@mui/material";
import FlexBox from "components/FlexBox";
import CustomTable from "components/userManagement/CustomTable";
import useTitle from "hooks/useTitle";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Remote from "../../remote/Remote";
import ApiService from "../../remote/ApiService";
import IApiResultData from "../../remote/model/IApiResultData";
import toast from "react-hot-toast";
import AppContainer from "../../components/core/AppContainer";
import { ApplicationElementsMessages } from "utils/AppMessages/AppMessages";
import { LayoutNoData } from "../../components/core/LayoutNoData";
import { TicketItemDto } from "../../model/ticket/TicketItemDto";
import ColumnTicketManagement from "./ColumnTicketManagement";
import IApiResult from "../../remote/model/IApiResult";
import ModalMessage from "../../components/core/modal/ModalMessage";

const StyledFlexBox = styled(FlexBox)(({ theme }) => ({
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  marginBottom: 20,
  [theme.breakpoints.down(500)]: {
    width: "100%",
    "& .MuiInputBase-root": { maxWidth: "100%" },
    "& .MuiButton-root": {
      width: "100%",
      marginTop: 15,
    },
  },
}));

const TicketItemsManagement = () => {
  useTitle(ApplicationElementsMessages.ManageCommunicationBox);
  const navigate = useNavigate();
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [ticketItems, setTicketItems] = useState<TicketItemDto[]>([]);
  const [loading, setLoading] = useState(false);
  const [deleteTicketItemId, setDeleteTicketItemId] = useState<number>(0);

  useEffect(() => {
    getTicketItems();
  }, []);

  const getTicketItems = () => {
    Remote.call(
      ApiService.getTicketItemsManagement(),
      (response: IApiResultData<TicketItemDto[]>) => {
        if (response.data) {
          setTicketItems(response.data);
        }
      },
      (onProgress: boolean) => {
        setLoading(onProgress);
      },
      (error: string) => {
        toast.error(error);
      }
    );
  };

  const onClick = (id: any, data: TicketItemDto) => {
    if (id === "edit") {
      if (data && data.id) {
        navigate(`/dashboard/addEditTicketItems/${data.id}`);
      }
    } else if (id === "delete") {
      setDeleteTicketItemId(data.id);
      setShowMessage(true);
    }
  };

  const deleteTicketItem = () => {
    Remote.call(
      ApiService.ticketItemDelete(deleteTicketItemId),
      (response: IApiResult) => {
        if (response.isSuccess) {
          toast.success(response.message);
          getTicketItems();
        } else {
          toast.error(response.message);
        }
      },
      (onProgress: boolean) => {
        setLoading(onProgress);
      },
      (error: string) => {
        toast.error(error);
      }
    );
  };

  const onResultModal = (res: any) => {
    if (res === true) deleteTicketItem();
  };

  return (
    <AppContainer isLoadingSpinner={loading}>
      <ModalMessage
        message={ApplicationElementsMessages.messageDeleteItem}
        open={showMessage}
        positiveText={ApplicationElementsMessages.delete}
        negativeText={ApplicationElementsMessages.Cancel}
        onResult={onResultModal}
        onClose={() => setShowMessage(false)}
      />
      <Box pt={2} pb={4}>
        <StyledFlexBox>
          <Link to="/dashboard/addEditTicketItems">
            <Button variant="contained">
              {ApplicationElementsMessages.add}
            </Button>
          </Link>
        </StyledFlexBox>
        <LayoutNoData show={ticketItems.length !== 0} />
        <div hidden={ticketItems.length === 0}>
          <CustomTable
            hidePagination
            columnShape={ColumnTicketManagement}
            data={ticketItems}
            rowClick={onClick}
          />
        </div>
      </Box>
    </AppContainer>
  );
};

export default TicketItemsManagement;
