export enum LoanRequestStatusType {
  Canceled = -1,
  MaxLoanRequest = 1,
  InitialSubmit = 2,
  GuarantorIntroduction = 3,
  GuarantorApproval = 4,
  RequestSubmit = 5,
  PaymentQueue = 6,
  Paid = 7,
  PaidInstallment = 8,
}

export const LoanRequestStatusTypeList = [
  { id: LoanRequestStatusType.Canceled, value: "لغو شده" },
  { id: LoanRequestStatusType.MaxLoanRequest, value: "درخواست سقف" },
  { id: LoanRequestStatusType.InitialSubmit, value: "ثبت اولیه" },
  { id: LoanRequestStatusType.GuarantorIntroduction, value: "معرفی ضامن" },
  { id: LoanRequestStatusType.GuarantorApproval, value: "تایید ضامن" },
  { id: LoanRequestStatusType.RequestSubmit, value: "ثبت درخواست" },
  { id: LoanRequestStatusType.PaymentQueue, value: "در صف پرداخت وام" },
  { id: LoanRequestStatusType.Paid, value: "پرداخت شده" },
  { id: LoanRequestStatusType.PaidInstallment, value: "اتمام اقساط" },
];

export const getLoanRequestStatusLabel = (
  type: LoanRequestStatusType
): string => {
  const status = LoanRequestStatusTypeList.find((status) => status.id === type);
  return status ? status.value : "ناشناخته";
};

export const FilterLoanRequestStatusTypeList = [
  { id: -100, value: "همه" },
  ...LoanRequestStatusTypeList,
];
