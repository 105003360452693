import { Box, Button, Card, Grid } from "@mui/material";
import LightTextField from "components/LightTextField";
import { useFormik } from "formik";
import useTitle from "hooks/useTitle";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import Remote from "../../remote/Remote";
import ApiService from "../../remote/ApiService";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { LoadingButton } from "@mui/lab";
import { ApplicationElementsMessages } from "utils/AppMessages/AppMessages";
import IApiResult from "remote/model/IApiResult";
import IApiResultData from "../../remote/model/IApiResultData";
import { OperatorDto } from "../../model/RoleModels/OperatorDto";
import AppSelect from "../../components/core/Select/App-Select";
import { RoleDto } from "../../model/RoleModels/RoleDto";

const AddEditOperator = () => {
  useTitle(ApplicationElementsMessages.DefineOperator);
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const { id } = useParams<{ id: string }>();
  const [operator, setOperator] = useState<OperatorDto>();
  const [roleList, setRoleList] = useState([{}]);

  const initialValues = {
    roleId: operator?.roleId ? operator?.roleId : "-1",
    userName: operator?.userName ? operator?.userName : "",
    name: operator?.name ? operator?.name : "",
    family: operator?.family ? operator?.family : "",
    password: operator?.password ? operator?.password : "",
    confirmPassword: operator?.confirmPassword ? operator?.confirmPassword : "",
  };

  useEffect(() => {
    getRoleInfo();
    if (id) getOperator();
  }, []);

  const getOperator = () => {
    Remote.call(
      ApiService.getOperatorById(id ? id : ""),
      (response: IApiResultData<OperatorDto>) => {
        if (response.data) setOperator(response.data);
      },
      (onProgress: boolean) => {
        setLoading(onProgress);
      },
      (error: string) => {
        toast.error(error);
      }
    );
  };

  const getRoleInfo = () => {
    Remote.call(
      ApiService.getRoles(),
      (response: IApiResultData<RoleDto[]>) => {
        if (response.data) {
          const result = [{ id: "-1", value: ApplicationElementsMessages.ChooseRole }];
          response.data.forEach((item) => {
            result.push({
              id: item.id ? item.id : "",
              value: item.name ? item.name : "",
            });
          });
          setRoleList(result);
        }
      },
      (onProgress: boolean) => {
        setLoading(onProgress);
      },
      (error: string) => {
        toast.error(error);
      }
    );
  };

  const validationSchema = Yup.object().shape({
    roleId: Yup.string().required("نقش را انتخاب نمایید"),
    userName: Yup.string()
      .min(3, "نام کاربری حداقل باید 3 کاراکتر باشد")
      .required("نام کاربری را صحیح وارد نمایید"),
    name: Yup.string()
      .min(3, "نام حداقل باید 3 کاراکتر باشد")
      .required("نام را صحیح وارد نمایید"),
    family: Yup.string()
      .min(3, "نام خانوادگی حداقل باید 3 کاراکتر باشد")
      .required("نام خانوادگی را صحیح وارد نمایید"),
    password: Yup.string()
      .min(6, "رمز عبور حداقل باید 6 کاراکتر باشد")
      .required("رمز عبور را صحیح وارد نمایید"),
    confirmPassword: Yup.string()
      .min(6, "تکرار رمز عبور حداقل باید 6 کاراکتر باشد")
      .required("تکرار رمز عبور را صحیح وارد نمایید"),
  });

  const { values, errors, handleChange, handleSubmit, touched } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values: any) => {
      const data = new OperatorDto(
        values.roleId,
        values.userName,
        values.name,
        values.family,
        values.password,
        values.confirmPassword
      );
      if (id) edit(data);
      else add(data);
    },
  });

  const add = (data: OperatorDto) => {
    Remote.call(
      ApiService.addOperator(data),
      (response: IApiResult) => {
        if (response.isSuccess) {
          toast.success(response.message);
          navigate("/dashboard/operator-list");
        } else {
          toast.error(response.message);
        }
      },
      (onProgress: boolean) => {
        setLoading(onProgress);
      },
      (error: string) => {
        toast.error(error);
      }
    );
  };

  const edit = (data: OperatorDto) => {
    Remote.call(
      ApiService.editOperator(data),
      (response: IApiResult) => {
        if (response.isSuccess) {
          toast.success(response.message);
          navigate("/dashboard/operator-list");
        } else {
          toast.error(response.message);
        }
      },
      (onProgress: boolean) => {
        setLoading(onProgress);
      },
      (error: string) => {
        toast.error(error);
      }
    );
  };

  return (
    <Box pt={2} pb={4}>
      <Button
        variant="contained"
        onClick={() => navigate("/dashboard/role-list")}
      >
        {ApplicationElementsMessages.Back}
      </Button>
      <br />
      <Card sx={{ padding: 4, marginTop: 2 }}>
        <Grid container spacing={3}>
          <Grid item md={12} xs={12}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item sm={6} xs={12}>
                  <LightTextField
                    fullWidth
                    name="userName"
                    placeholder={ApplicationElementsMessages.Username}
                    value={values.userName}
                    onChange={handleChange}
                    error={Boolean(touched.userName && errors.userName)}
                    helperText={touched.userName && errors.userName}
                  />
                </Grid>

                <Grid item sm={6} xs={12}>
                  <AppSelect
                    name="roleId"
                    placeholder={ApplicationElementsMessages.Role}
                    data={roleList}
                    onChange={handleChange}
                    value={values.roleId}
                  />
                </Grid>

                <Grid item sm={6} xs={12}>
                  <LightTextField
                    fullWidth
                    name="name"
                    placeholder={ApplicationElementsMessages.Name}
                    value={values.name}
                    onChange={handleChange}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </Grid>

                <Grid item sm={6} xs={12}>
                  <LightTextField
                    fullWidth
                    name="family"
                    placeholder={ApplicationElementsMessages.Family}
                    value={values.family}
                    onChange={handleChange}
                    error={Boolean(touched.family && errors.family)}
                    helperText={touched.family && errors.family}
                  />
                </Grid>

                <Grid item sm={6} xs={12}>
                  <LightTextField
                    fullWidth
                    name="password"
                    placeholder={ApplicationElementsMessages.Password}
                    value={values.password}
                    onChange={handleChange}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                  />
                </Grid>

                <Grid item sm={6} xs={12}>
                  <LightTextField
                    fullWidth
                    name="confirmPassword"
                    placeholder={ApplicationElementsMessages.ConfirmPassword}
                    value={values.confirmPassword}
                    onChange={handleChange}
                    error={Boolean(
                      touched.confirmPassword && errors.confirmPassword
                    )}
                    helperText={
                      touched.confirmPassword && errors.confirmPassword
                    }
                  />
                </Grid>

                <Grid item sm={12} xs={12}>
                  <Grid item sm={2} xs={12}>
                    <LoadingButton
                      type="submit"
                      loading={loading}
                      fullWidth
                      variant="contained"
                    >
                      {id
                        ? ApplicationElementsMessages.edit
                        : ApplicationElementsMessages.Submit}
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default AddEditOperator;
