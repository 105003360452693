import { Badge, Box, ListItemButton, styled } from "@mui/material";
import { FC, MouseEventHandler } from "react";
import { H6 } from "../Typography";
import SubMenuPopoverDto from "../../model/SubMenuPopoverDto";
interface SubMenuProps {
  subMenu: SubMenuPopoverDto[] | undefined;
  handleActiveMainMenu(menuItem: any): MouseEventHandler<any>;
  active: string;
}

const DashboardSubMenu: FC<SubMenuProps> = ({
  subMenu,
  handleActiveMainMenu,
  active,
}) => {
  if (subMenu === undefined) return <div />;

  return (
    <div>
      {subMenu?.map((nav, index) => (
        <div key={index} onClick={handleActiveMainMenu(nav)}>
          <Box
            sx={{
              width: "100%",
              paddingTop: "10px",
              overflow: "hidden",
              flexDirection: "column",
              textAlign: "center",
              marginTop: "0px",
              marginBottom: "20px",
            }}
          >
            <Badge color="error" badgeContent={0}>
              <nav.icon
                sx={{
                  color:
                    active === nav.title ? "primary.main" : "secondary.400",
                }}
              />
            </Badge>
            <H6
              mt={0.5}
              fontSize={13}
              color={active === nav.title ? "primary.main" : "secondary.400"}
            >
              {nav.title}
            </H6>
          </Box>
        </div>
      ))}
    </div>
  );
};

export default DashboardSubMenu;
