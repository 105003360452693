import "./LoadingSpinner.css";
import {CircularProgress} from "@mui/material";


const LoadingSpinner = () => {
    return (
        <div className="loading">
            <CircularProgress style={{color: 'primary.main'}}/>
        </div>
    );
};


export default LoadingSpinner;
