import SettingsProvider from "contexts/SettingsContext";
import TitleContextProvider from "contexts/TitleContext";
import App from "./App";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "nprogress/nprogress.css";
import "react-image-lightbox/style.css";
import "simplebar/dist/simplebar.min.css";

ReactDOM.render(
  <SettingsProvider>
    <TitleContextProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </TitleContextProvider>
  </SettingsProvider>,
  document.getElementById("root")
);
