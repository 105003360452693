import {
    GridView as UserGridIcon,
    Login as LoginIcon,
    PersonAdd as AddUserIcon,
    Logout as LogoutIcon,
    ChatBubbleOutlineOutlined,
    MenuOutlined,
    DashboardOutlined,
    DashboardCustomizeOutlined,
    DashboardCustomizeRounded,
    DashboardRounded,
    SupervisedUserCircleRounded,
    ManageAccountsRounded,
    SettingsAccessibilityRounded,
    SwitchAccessShortcutRounded,
    PersonRounded,
    LockPersonRounded,
    ChatBubbleRounded,
    MessageRounded,
    BugReportRounded,
    SettingsRounded,
    LogoutRounded,
    ExitToAppRounded,
    AllInboxRounded,
    SwapHoriz,
    SwapHorizRounded, RequestPageRounded,
    ReportRounded, PaymentRounded,
} from "@mui/icons-material";
import DashboardIcon from "./Dashboard";
import SessionsIcon from "./Sessions";
import UserManagementIcon from "./UserManagement";
import UserProfileIcon from "./UserProfile";
import ChatIcon from "./ChatIcon";
import WarningIcon from "@mui/icons-material/Warning";
import SettingsIcon from "@mui/icons-material/Settings";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    LoginIcon,
    AddUserIcon,
    UserGridIcon,
    WarningIcon,
    DashboardIcon,
    UserProfileIcon,
    UserManagementIcon,
    SessionsIcon,
    ChatIcon,


    ChatBubbleOutlineOutlined,
    ManageAccountsRounded,
    DashboardRounded,
    SettingsAccessibilityRounded,
    PersonRounded,
    LockPersonRounded,
    ChatBubbleRounded,
    SupervisedUserCircleRounded,
    MessageRounded,
    BugReportRounded,
    SettingsRounded,
    ExitToAppRounded,
    AllInboxRounded,
    SwapHorizRounded,
    RequestPageRounded,
    ReportRounded,
    PaymentRounded,
};
