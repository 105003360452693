import { SvgIcon, SvgIconProps } from "@mui/material";

const NotificationsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 19 20" {...props}>
      <path
        d="M9.23963 2.27017C8.82192 2.27017 8.48291 1.93116 8.48291 1.51345V0.756723C8.48291 0.339012 8.82192 0 9.23963 0C9.65734 0 9.99636 0.339012 9.99636 0.756723V1.51345C9.99636 1.93116 9.65734 2.27017 9.23963 2.27017Z"
        fill="currentColor"
      />
      <path
        d="M15.8434 9.19936C15.4256 9.19936 15.0866 8.86034 15.0866 8.44263C15.0866 5.36731 12.5895 2.74678 9.52018 2.60149C6.44713 2.4596 3.70742 4.82625 3.41797 7.88379C3.40057 8.06654 3.39186 8.25421 3.39186 8.44263C3.39186 8.86034 3.05285 9.19936 2.63514 9.19936C2.21743 9.19936 1.87842 8.86034 1.87842 8.44263C1.87842 8.20654 1.88939 7.97044 1.91134 7.74115C2.2757 3.89094 5.72522 0.907943 9.59169 1.08994C13.4563 1.27268 16.6001 4.57124 16.6001 8.44263C16.6001 8.86072 16.2611 9.19936 15.8434 9.19936Z"
        fill="currentColor"
      />
      <path
        d="M17.262 14.3613C17.0755 14.3613 16.8882 14.2924 16.7418 14.1543C15.6899 13.1585 15.0864 11.7563 15.0864 10.3075C15.0864 9.88979 15.4254 9.55078 15.8431 9.55078C16.2609 9.55078 16.5999 9.88979 16.5999 10.3075C16.5999 11.3423 17.0308 12.3442 17.7823 13.0552C18.0857 13.3423 18.0989 13.8213 17.8118 14.1252C17.6631 14.2818 17.4625 14.3613 17.262 14.3613Z"
        fill="currentColor"
      />
      <path
        d="M1.21625 14.3613C1.01534 14.3613 0.815186 14.2822 0.66649 14.1248C0.379314 13.8213 0.392556 13.3423 0.696002 13.0552C1.44743 12.3442 1.87838 11.3427 1.87838 10.3075C1.87838 9.88979 2.21739 9.55078 2.6351 9.55078C3.05282 9.55078 3.39183 9.88979 3.39183 10.3075C3.39183 11.757 2.78834 13.1592 1.7365 14.1543C1.58969 14.2924 1.40278 14.3613 1.21625 14.3613Z"
        fill="currentColor"
      />
      <path
        d="M9.23967 19.9993C7.18895 19.9993 5.45605 18.2664 5.45605 16.2157C5.45605 15.798 5.79507 15.459 6.21278 15.459C6.63049 15.459 6.9695 15.798 6.9695 16.2157C6.9695 17.4461 8.00924 18.4859 9.23967 18.4859C10.4701 18.4859 11.5098 17.4461 11.5098 16.2157C11.5098 15.798 11.8488 15.459 12.2666 15.459C12.6843 15.459 13.0233 15.798 13.0233 16.2157C13.0233 18.2664 11.2904 19.9993 9.23967 19.9993Z"
        fill="currentColor"
      />
      <path
        d="M2.25657 16.9733C1.3345 16.9733 0.514218 16.4096 0.166503 15.537C-0.181589 14.6634 0.0261311 13.6891 0.695831 13.055C0.999277 12.7674 1.4779 12.7807 1.76584 13.0841C2.05301 13.3876 2.04015 13.8666 1.7367 14.1541C1.40526 14.4682 1.52709 14.8636 1.57249 14.9771C1.61752 15.0898 1.80027 15.4599 2.25657 15.4599C2.67428 15.4599 3.01329 15.7989 3.01329 16.2166C3.01329 16.6343 2.67466 16.9733 2.25657 16.9733Z"
        fill="currentColor"
      />
      <path
        d="M16.2221 16.9737C15.8043 16.9737 15.4653 16.6347 15.4653 16.217C15.4653 15.7993 15.8043 15.4603 16.2221 15.4603C16.6787 15.4603 16.8619 15.0887 16.9073 14.9749C16.9523 14.8617 17.0737 14.4679 16.7423 14.1542C16.4389 13.8666 16.4256 13.388 16.7128 13.0842C17.0003 12.7807 17.479 12.7675 17.7828 13.0547C18.4525 13.6888 18.6606 14.6623 18.3133 15.5345C17.9652 16.4088 17.1441 16.9737 16.2221 16.9737Z"
        fill="currentColor"
      />
      <path
        d="M2.63465 11.0628C2.21694 11.0628 1.87793 10.7238 1.87793 10.3061V8.44227C1.87793 8.02456 2.21694 7.68555 2.63465 7.68555C3.05236 7.68555 3.39138 8.02456 3.39138 8.44227V10.3061C3.39138 10.7238 3.05274 11.0628 2.63465 11.0628Z"
        fill="currentColor"
      />
      <path
        d="M15.8436 11.0628C15.4259 11.0628 15.0869 10.7238 15.0869 10.3061V8.44227C15.0869 8.02456 15.4259 7.68555 15.8436 7.68555C16.2613 7.68555 16.6004 8.02456 16.6004 8.44227V10.3061C16.6004 10.7238 16.2613 11.0628 15.8436 11.0628Z"
        fill="currentColor"
      />
      <path
        d="M16.222 16.9724H2.25672C1.83901 16.9724 1.5 16.6334 1.5 16.2157C1.5 15.798 1.83901 15.459 2.25672 15.459H16.2217C16.6394 15.459 16.9784 15.798 16.9784 16.2157C16.9784 16.6334 16.6398 16.9724 16.222 16.9724Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default NotificationsIcon;
