import { LoadingButton } from "@mui/lab";
import { Box, Button, Card, FormHelperText } from "@mui/material";
import { TextFieldWrapper } from "components/authentication/StyledComponents";
import FlexBox from "components/FlexBox";
import LightTextField from "components/LightTextField";
import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Remote from "../../remote/Remote";
import ApiService from "../../remote/ApiService";
import IApiResultData from "../../remote/model/IApiResultData";
import JwtToken from "../../remote/model/JwtToken";
import { H1, Paragraph } from "../../components/Typography";
import { UserManager } from "../../utils/UserManager";
import {
  ApplicationElementsMessages,
  ErrorMessages,
} from "utils/AppMessages/AppMessages";

const Login: FC = () => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    UserManager.get().deleteToken();
  }, []);

  const initialValues = {
    userName: "",
    password: "",
    submit: null,
    remember: true,
  };
  // form field value validation schema
  const validationSchema = Yup.object().shape({
    userName: Yup.string()
      .min(3, ErrorMessages.InvalidUsername)
      .max(50, ErrorMessages.InvalidUsername)
      .required(ErrorMessages.InvalidUsername),
    password: Yup.string()
      .min(4, ErrorMessages.InvalidPassword)
      .required(ErrorMessages.InvalidPassword),
  });

  const { errors, values, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: async (values: any) => {
        Remote.call(
          ApiService.login(values.userName, values.password),
          (response: IApiResultData<JwtToken>) => {
            if (response.data) {
              UserManager.get().saveToken(response.data);
              navigate("/dashboard");
            }
          },
          (onProgress: boolean) => {
            setLoading(onProgress);
          },
          (error: string) => {
            setError(error);
          }
        );
      },
    });

  return (
    <FlexBox
      sx={{
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        width: "100vw",
        height: "100vh",
      }}
    >
      <Card sx={{ padding: 8, maxWidth: 600, boxShadow: 1, margin: 2 }}>
        <FlexBox
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          mb={5}
        >
          <Box width={80} mb={1}>
            <img src="/logo192.png" width="100%" alt="Logo" />
          </Box>
          <H1 fontSize={24} fontWeight={700}>
            {ApplicationElementsMessages.LoginToPanel}
          </H1>
        </FlexBox>

        <FlexBox justifyContent="space-between" flexWrap="wrap" my="1rem">
          <form noValidate onSubmit={handleSubmit} style={{ width: "100%" }}>
            <FlexBox justifyContent="space-between" flexWrap="wrap">
              <TextFieldWrapper>
                <Paragraph fontWeight={600} mb={1}>
                  {ApplicationElementsMessages.Username}
                </Paragraph>
                <LightTextField
                  fullWidth
                  dir="ltr"
                  name="userName"
                  type="userName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.userName || ""}
                  error={Boolean(touched.userName && errors.userName)}
                  helperText={touched.userName && errors.userName}
                />
              </TextFieldWrapper>

              <TextFieldWrapper>
                <Paragraph fontWeight={600} mb={1}>
                  {ApplicationElementsMessages.Password}
                </Paragraph>
                <LightTextField
                  fullWidth
                  dir="ltr"
                  name="password"
                  type="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password || ""}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                />
              </TextFieldWrapper>
            </FlexBox>

            {error && (
              <FormHelperText
                error
                sx={{
                  mt: 2,
                  fontSize: 13,
                  fontWeight: 500,
                  textAlign: "center",
                }}
              >
                {error}
              </FormHelperText>
            )}

            <Box sx={{ mt: 4 }}>
              {loading ? (
                <LoadingButton loading fullWidth variant="contained">
                  {ApplicationElementsMessages.Login}
                </LoadingButton>
              ) : (
                <Button fullWidth type="submit" variant="contained">
                  {ApplicationElementsMessages.Login}
                </Button>
              )}
            </Box>
          </form>
        </FlexBox>
      </Card>
    </FlexBox>
  );
};

export default Login;
