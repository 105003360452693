import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Drawer,
  List,
  ListItemButton,
  styled,
  Theme,
  useMediaQuery,
} from "@mui/material";
import Icons from "icons/sidebar";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ScrollBar from "simplebar-react";
import { H6 } from "../Typography";
import {
  ExpandLessRounded,
  RequestPageRounded,
  SwapHorizRounded,
} from "@mui/icons-material";
import DashboardSubMenu from "./DashboardSubMenu";
import MenuPopoverDto from "model/MenuPopoverDto";
import { ApplicationElementsMessages } from "utils/AppMessages/AppMessages";
import Remote from "../../remote/Remote";
import ApiService from "../../remote/ApiService";
import IApiResultData from "../../remote/model/IApiResultData";
import { CurrentUserRolePermissionDto } from "../../model/RoleModels/CurrentUserRolePermissionDto";
import { UserManager } from "../../utils/UserManager";
import toast from "react-hot-toast";
import { PermissionsHelper } from "../AccessControl/PermissionsHelper";

// root component interface
interface SideNavBarProps {
  showMobileSideBar: boolean;
  closeMobileSideBar: () => void;
}

// custom styled components
const MainMenu = styled(Box)(({ theme }) => ({
  right: 0,
  width: 135,
  height: "100vh",
  position: "fixed",
  boxShadow: theme.shadows[2],
  transition: "right 0.3s ease",
  zIndex: theme.zIndex.drawer + 11,
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.down("md")]: { right: -130 },
  "& .simplebar-track.simplebar-vertical": { width: 7 },
  "& .simplebar-scrollbar:before": {
    background: theme.palette.text.primary,
  },
}));

const StyledListItemButton = styled(ListItemButton)(() => ({
  justifyContent: "center",
  "&:hover": { backgroundColor: "transparent" },
}));

// root component
const DashboardSideBar: FC<SideNavBarProps> = ({
  showMobileSideBar,
  closeMobileSideBar,
}) => {
  const navigate = useNavigate();

  const [topMenusList, setTopMenusList] = useState<MenuPopoverDto[]>([]);
  const [active, setActive] = useState<string>("Dashboard");
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  useEffect(() => {
    getUserPermissions();
  }, []);

  const getUserPermissions = () => {
    Remote.call(
      ApiService.getUserPermissions(),
      (response: IApiResultData<CurrentUserRolePermissionDto>) => {
        if (response.isSuccess && response.data) {
          UserManager.get().saveUserRolePermissions(response.data);
          const res = SidebarMenu(response.data);
          setTopMenusList(res);
        }
      },
      (onProgress: boolean) => {
        //setLoading(onProgress);
      },
      (error: string) => {
        toast.error(error);
      }
    );
  };

  const handleActiveMainMenu = (menuItem: any) => () => {
    setActive(menuItem.title);
    navigate(menuItem.path);
    closeMobileSideBar();
  };

  // main menus content
  const mainSideBarContent = (
    <List sx={{ height: "100%" }}>
      <StyledListItemButton disableRipple>
        <img src="/logo192.png" alt="Logo" width={70} />
      </StyledListItemButton>

      <ScrollBar
        style={{ maxHeight: "calc(100% - 50px)", paddingBottom: "100px" }}
      >
        {topMenusList
          .filter((m) => m.showMenu)
          .map((nav, index) => (
            <StyledListItemButton
              key={index}
              onClick={
                nav.subMenu == null ? handleActiveMainMenu(nav) : undefined
              }
            >
              <Box
                sx={{
                  width: "100%",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  overflow: "hidden",
                  flexDirection: "column",
                  textAlign: "center",
                  backgroundColor: "#fafafa",
                  borderRadius: "10px",
                }}
              >
                <nav.icon
                  sx={{
                    color:
                      active === nav.title ? "primary.main" : "secondary.400",
                  }}
                />

                <Accordion
                  hidden={nav.subMenu == null}
                  elevation={0}
                  sx={{
                    padding: "0px",
                    backgroundColor: "#fafafa",
                    borderRadius: "10px",
                  }}
                >
                  <AccordionSummary
                    sx={{ width: "100%", padding: "0px" }}
                    expandIcon={
                      <ExpandLessRounded
                        sx={{
                          width: 20,
                          height: 20,
                          color:
                            active === nav.title
                              ? "primary.main"
                              : "secondary.400",
                        }}
                      />
                    }
                  >
                    <div style={{ width: "100%", textAlign: "center" }}>
                      <H6
                        fontSize={13}
                        color={
                          active === nav.title
                            ? "primary.main"
                            : "secondary.400"
                        }
                        sx={{ with: "100%", textAlign: "center" }}
                      >
                        {nav.title}
                      </H6>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      padding: "0px",
                      paddingBottom: "10px",
                      backgroundColor: "#fafafa",
                      borderRadius: "10px",
                    }}
                  >
                    <DashboardSubMenu
                      subMenu={nav.subMenu?.filter((sub) => sub.showMenu)}
                      handleActiveMainMenu={handleActiveMainMenu}
                      active={active}
                    />
                  </AccordionDetails>
                </Accordion>

                <H6
                  hidden={nav.subMenu != null}
                  fontSize={11}
                  color={
                    active === nav.title ? "primary.main" : "secondary.400"
                  }
                >
                  {nav.title}
                </H6>
              </Box>
            </StyledListItemButton>
          ))}
      </ScrollBar>
    </List>
  );

  // for mobile device
  if (downMd) {
    return (
      <Drawer
        anchor="right"
        open={showMobileSideBar}
        onClose={closeMobileSideBar}
        PaperProps={{ sx: { width: 130 } }}
      >
        <Box
          sx={{
            height: "100%",
            display: "flex",
            width: "inherit",
            position: "fixed",
            overflow: "hidden",
            flexDirection: "column",
            boxShadow: (theme: any) => theme.shadows[1],
            backgroundColor: (theme: any) => theme.palette.background.paper,
            "& .simplebar-track.simplebar-vertical": { width: 7 },
            "& .simplebar-scrollbar:before": {
              background: (theme: any) => theme.palette.text.primary,
            },
          }}
        >
          {mainSideBarContent}
        </Box>
      </Drawer>
    );
  }

  return <MainMenu>{mainSideBarContent}</MainMenu>;
};

const SidebarMenu: (data?: CurrentUserRolePermissionDto) => any[] = (
  data?: CurrentUserRolePermissionDto
) => {
  if (!data) return [];
  const permissions = new PermissionsHelper(data);
  return [
    {
      id: 1,
      title: ApplicationElementsMessages.Dashboard,
      icon: Icons.DashboardRounded,
      path: "/dashboard",
      showMenu: true,
    },
    {
      id: 2,
      title: ApplicationElementsMessages.Users,
      icon: Icons.ManageAccountsRounded,
      path: "/dashboard/user-list",
      showMenu: permissions.hasPermissions([permissions.Access.InstallmentPayments]),
    },
    {
      id: 11,
      title: ApplicationElementsMessages.installmentPayments,
      icon: Icons.PaymentRounded,
      path: "/dashboard/installmentPayments",
      showMenu: permissions.hasPermissions([permissions.Access.GetUsers]),
    },
    {
      id: 9,
      title: ApplicationElementsMessages.LoanInfo,
      icon: Icons.RequestPageRounded,
      path: "",
      showMenu: permissions.hasPermissions([]),
      subMenu: [
        {
          id: 901,
          title: ApplicationElementsMessages.requestLoan,
          icon: Icons.RequestPageRounded,
          path: "/dashboard/requestsLoan",
          showMenu: permissions.hasPermissions([permissions.Access.RequestsLoan]),
        },
        {
          id: 902,
          title: ApplicationElementsMessages.currentLoan,
          icon: Icons.RequestPageRounded,
          path: "/dashboard/currentLoan",
          showMenu: permissions.hasPermissions([permissions.Access.CurrentLoan]),
        },
      ],
    },
    {
      id: 3,
      title: ApplicationElementsMessages.RoleManagement,
      icon: Icons.SettingsAccessibilityRounded,
      path: "",
      showMenu: permissions.hasPermissions([]),
      subMenu: [
        {
          id: 301,
          title: ApplicationElementsMessages.Operators,
          icon: Icons.PersonRounded,
          path: "/dashboard/operator-list",
          showMenu: permissions.hasPermissions([]),
        },
        {
          id: 302,
          title: ApplicationElementsMessages.Roles,
          icon: Icons.LockPersonRounded,
          path: "/dashboard/role-list",
          showMenu: permissions.hasPermissions([]),
        },
      ],
    },
    {
      id: 4,
      title: ApplicationElementsMessages.CommunicationBox,
      icon: Icons.AllInboxRounded,
      path: "",
      showMenu: permissions.hasPermissions([permissions.Access.GetTickets]),
      subMenu: [
        {
          id: 402,
          title: ApplicationElementsMessages.CommunicationBoxMessages,
          icon: Icons.MessageRounded,
          path: "/dashboard/tickets",
          showMenu: permissions.hasPermissions([permissions.Access.GetTickets]),
        },
        {
          id: 401,
          title: ApplicationElementsMessages.ManageCommunicationBox,
          icon: Icons.SupervisedUserCircleRounded,
          showMenu: permissions.hasPermissions([]),
          path: "/dashboard/ticketItemsManagement",
        },
      ],
    },
    {
      id: 8,
      title: ApplicationElementsMessages.transactions,
      icon: Icons.SwapHorizRounded,
      path: "/dashboard/transactions",
      showMenu: permissions.hasPermissions([permissions.Access.Transactions]),
    },

    {
      id: 10,
      title: ApplicationElementsMessages.reports,
      icon: Icons.ReportRounded,
      path: "/dashboard/reports",
      showMenu: permissions.hasPermissions([permissions.Access.GetReports]),
    },
    {
      id: 5,
      title: ApplicationElementsMessages.LogEntries,
      icon: Icons.BugReportRounded,
      path: "/dashboard/logEntries",
      showMenu: permissions.hasPermissions([permissions.Access.GetLogEntries]),
    },
    {
      id: 6,
      title: ApplicationElementsMessages.Settings,
      icon: Icons.SettingsRounded,
      path: "/dashboard/settings",
      showMenu: permissions.hasPermissions([permissions.Access.GetSettings]),
    },
    {
      id: 7,
      title: ApplicationElementsMessages.Logout,
      icon: Icons.ExitToAppRounded,
      path: "/login",
      showMenu: true,
    },
  ];
};

export default DashboardSideBar;
