export class OperatorDto {
    id: string = "";
    roleId: string = "";
    userName: string = "";
    name: string = "";
    family: string = "";
    password: string = "";
    confirmPassword: string = "";

    constructor(
        roleId: string,
        userName: string,
        name: string,
        family: string,
        password: string,
        confirmPassword: string,
    ) {
        this.roleId = roleId;
        this.userName = userName;
        this.name = name;
        this.family = family;
        this.password = password;
        this.confirmPassword = confirmPassword;
    }
}
