import api from "./api";
import Url from "./RequestUrls";
import { RoleDto } from "../model/RoleModels/RoleDto";
import SettingsDto from "model/Settings/SettingsDto";
import { RolePermissionDto } from "model/RoleModels/RolePermissionDto";
import { OperatorDto } from "../model/RoleModels/OperatorDto";
import { CreateTicketItemDto } from "../model/ticket/CreateTicketItemDto";
import { UserDto } from "model/UserDto";
import { mainUrl } from "./HttpConstants";
import axios from "axios";
import SetMembershiFeePayment from "model/SetMembershiFeePayment";
import { LoanRequestPaymentStatus } from "model/Enums/LoanRequestPaymentStatus";

class ApiService {
  /**auth*/
  login(username: string, password: string): any {
    const data = { username: username, password: password, device: "web" };
    return api.post(Url.login, data);
  }

  changeAdminPassword(data: any): any {
    return api.post(Url.changeAdminPassword, data);
  }

  getUserPermissions(): any {
    return api.get(Url.getUserPermissions);
  }

  async refreshToken(data: any): Promise<any> {
    return await api.post(Url.refreshToken, data);
  }

  /**user*/
  getUsers(Search: string, paging: string, filterType: number = 0): any {
    return api.get(
      `${Url.getUsers}?filterType=${filterType}&Search=${Search}${paging}`
    );
  }

  getUserInfo(userId: string): any {
    return api.get(`${Url.getUserInfo}?userId=${userId}`);
  }

  modifyUserInfo(data: UserDto): any {
    return api.post(Url.modifyUserInfo, data);
  }

  changeUserStatus(data: any): any {
    return api.post(Url.changeUserStatus, data);
  }

  SetMembershiFeePayment(data: SetMembershiFeePayment) {
    return api.post(Url.setMembershiFeePayment, data);
  }

  /**role*/
  getRoles(): any {
    return api.get(`${Url.getRoles}`);
  }

  getRoleInfo(roleId: string): any {
    return api.get(`${Url.getRoleInfo}?roleId=${roleId}`);
  }

  addRole(data: RoleDto): any {
    return api.post(Url.addRole, data);
  }

  editRole(data: RoleDto): any {
    return api.post(Url.editRole, data);
  }

  getRolePermissions(roleName: string): any {
    return api.get(`${Url.getRolePermissions}?name=${roleName}`);
  }

  initializeDynamicPermission(): any {
    return api.post(`${Url.initializeDynamicPermission}`);
  }

  modifyRolePermissions(data: RolePermissionDto): any {
    return api.post(Url.modifyRolePermissions, data);
  }

  /**public*/
  getDashboardSummary(): any {
    return api.get(Url.getDashboardSummary);
  }

  getLogEntries(paging: string): any {
    return api.get(`${Url.getLogEntries}?${paging}`);
  }

  getSmsRemain(): any {
    return api.get(Url.getSmsRemain);
  }

  getSettings(): any {
    return api.get(Url.getSettings);
  }

  modifySettings(data: SettingsDto): any {
    return api.post(Url.modifySettings, data);
  }

  uploadMembershipFeeFile(data: any): any {
    return api.post(Url.uploadMembershipFeeFile, data);
  }

  uploadUsersListFile(data: any): any {
    return api.post(Url.uploadUsersListFile, data);
  }

  /**Loan*/
  uploadUsersLoanFile(data: any): any {
    return api.post(Url.uploadUsersLoanFile, data);
  }

  getUserLoan(userId: string): any {
    return api.get(`${Url.getUserLoan}?userId=${userId}`);
  }

  getUserMembershipFeeInfo(userId: string): any {
    return api.get(`${Url.getUserMembershipFeeInfo}?userId=${userId}`);
  }

  /**operators*/
  getOperators(filter: string, paging: string): any {
    return api.get(`${Url.getOperators}?Search=${filter}${paging}`);
  }

  getOperatorById(id: string): any {
    return api.get(`${Url.getOperatorById}?id=${id}`);
  }

  addOperator(data: OperatorDto): any {
    return api.post(Url.addOperator, data);
  }

  editOperator(data: OperatorDto): any {
    return api.post(Url.editOperator, data);
  }

  deleteOperator(id: string): any {
    return api.post(`${Url.deleteOperator}?operatorId=${id}`);
  }

  /**ticket*/
  getTicketItemsManagement(): any {
    return api.get(`${Url.ticketItemsManagement}`);
  }
  getTicketItemById(id: string): any {
    return api.get(`${Url.getTicketItemById}?id=${id}`);
  }
  ticketItemCreate(data: CreateTicketItemDto): any {
    return api.post(Url.ticketItemsManagement, data);
  }
  ticketItemEdit(data: CreateTicketItemDto): any {
    return api.put(Url.ticketItemsManagement, data);
  }
  ticketItemDelete(id: number): any {
    return api.delete(`${Url.ticketItemsManagement}?id=${id}`);
  }

  getTickets(paging: string): any {
    return api.get(`${Url.getTickets}?${paging}`);
  }

  getTicketMessages(ticketId: number, paging: string): any {
    return api.get(`${Url.getTicketMessages}?TicketId=${ticketId}${paging}`);
  }

  addNewMessage(data: any): any {
    return api.post(Url.addNewMessage, data);
  }

  getTransactions(filter: string, paging: string): any {
    return api.get(`${Url.getTransactions}?Search=${filter}${paging}`);
  }

  getRequestsLoan(search: string, paging: string, filterType: number = 0): any {
    return api.get(
      `${Url.getRequestsLoan}?filterType=${filterType}&Search=${search}${paging}`
    );
  }
  getRequestLoanById(id: string): any {
    return api.get(`${Url.getRequestLoanById}?id=${id}`);
  }

  updateStatusRequestLoan(id: number, status: number): any {
    const data = { loanRequestId: id, status: status };
    return api.put(Url.updateStatusRequestLoan, data);
  }

  setLoanRequestPaymentStatus(
    loanRequestPaymentId: number,
    status: LoanRequestPaymentStatus
  ): any {
    const data = { loanRequestPaymentId, status };
    return api.put(Url.setLoanRequestPaymentStatus, data);
  }

  getCurrentLoan(search: string, paging: string): any {
    return api.get(`${Url.getCurrentLoan}?Search=${search}${paging}`);
  }

  getCurrentLoanById(id: string): any {
    return api.get(`${Url.getRequestLoanById}?id=${id}`);
  }

  setLoanRequestInstallmentDuration(
    loanRequestId: number,
    durationInMonth: number
  ): any {
    const data = { loanRequestId, durationInMonth };
    return api.put(Url.setLoanRequestInstallmentDuration, data);
  }

  getInstallmentPayments(filter: string, paging: string): any {
    return api.get(`${Url.getInstallmentPayments}?Search=${filter}${paging}`);
  }

  /**Enum*/
  getJobLocationList(): any {
    return axios.get(`${mainUrl}/api/users/v1/Client/GetJobLocationList`);
  }
}

export default new ApiService();
