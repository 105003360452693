import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { ImageConverter } from "../../utils/ImageConverter";
import { AddAPhotoRounded } from "@mui/icons-material";

const ImagePicker = (props: {
  name?: string;
  width?: number;
  height?: number;
  value?: string;
  onSelect?: (image: string) => void;
}) => {
  const [image, setImage] = useState<any>(props?.value);
  useEffect(() => {
    if (props?.value != null) setImage(props?.value);
  }, [props?.value]);

  const selectFileInput = (data: any) => {
    const file = data.target.files[0];
    if (file) {
      toBase64(file)
        .then((res) => {
          const base64 = res as string;
          setImage(base64);
          if (props.onSelect != null && base64) {
            props.onSelect(base64);
          }
        })
        .catch();
    }
  };
  const toBase64 = async (file: any) => {
    if (file) {
      return await ImageConverter.toBase64(file);
    }
  };

  const size = props?.width ? props.width : 150;

  return (
    <div>
      <label htmlFor="raised-button-file">
        <Box
          sx={{
            width: size,
            height: size,
            display: "flex",
            textAlign: "center",
            alignContent: "center",
            verticalAlign: "center",
            cursor: "pointer",
            border: 2,
            borderColor: "#e8e8e8",
            borderRadius: 2,
          }}
        >
          <img
            alt=""
            hidden={image == null}
            id={props?.name ? props?.name : ""}
            src={image}
            width={size}
            height={size}
            style={{
              paddingLeft: 6,
              paddingBottom: 6,
              paddingRight: 2,
              paddingTop: 2,
            }}
          />

          <Box sx={{ width: size, height: size }}>
            <AddAPhotoRounded />
          </Box>
        </Box>
      </label>
      <input
        accept="image/*"
        style={{ display: "none" }}
        id="raised-button-file"
        type="file"
        onInput={selectFileInput}
      />
    </div>
  );
};
export default ImagePicker;
