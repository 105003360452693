import { Box } from "@mui/material";
import CustomTable from "components/userManagement/CustomTable";
import React, { useState } from "react";
import AppContainer from "components/core/AppContainer";
import LoanDto from "model/LoanDto";
import { H6 } from "components/Typography";
import { NumberHelper } from "utils/NumberHelper";
import { getLoanRequestStatusLabel } from "model/Enums/LoanRequestStatusType";

const DetailUserLoan = (props: { loanInfo: LoanDto }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loan, setLoan] = useState<LoanDto[]>([props.loanInfo]);

  return (
    <AppContainer isLoadingSpinner={loading}>
      <Box>
        <CustomTable columnShape={columnLoan} data={loan} hidePagination />
      </Box>
    </AppContainer>
  );
};

const columnLoan = [
  {
    Header: "شناسه",
    accessor: "id",
    minWidth: 50,
    Cell: ({ row }: any) => {
      const { id }: LoanDto = row.original;
      return <H6 color="text.primary">{id}</H6>;
    },
  },
  {
    Header: "كل مبلغ",
    accessor: "totalAmount",
    minWidth: 150,
    Cell: ({ row }: any) => {
      const { totalAmount }: LoanDto = row.original;
      return (
        <H6 color="text.primary">{NumberHelper.separateComma(totalAmount)}</H6>
      );
    },
  },
  {
    Header: "مبلغ هر قسط",
    accessor: "monthlyInstallmentAmount",
    minWidth: 150,
    Cell: ({ row }: any) => {
      const { monthlyInstallmentAmount }: LoanDto = row.original;
      return (
        <H6 color="text.primary">
          {NumberHelper.separateComma(monthlyInstallmentAmount)}
        </H6>
      );
    },
  },
  {
    Header: "مبلغ باقيمانده",
    accessor: "remainingAmount",
    minWidth: 150,
    Cell: ({ row }: any) => {
      const { remainingAmount }: LoanDto = row.original;
      return (
        <H6 color="text.primary">
          {NumberHelper.separateComma(remainingAmount)}
        </H6>
      );
    },
  },
  {
    Header: "وضعیت درخواست",
    accessor: "status",
    minWidth: 150,
    Cell: ({ row }: any) => {
      const { status }: LoanDto = row.original;
      return <H6 color="text.primary">{getLoanRequestStatusLabel(status)}</H6>;
    },
  },
];

export default DetailUserLoan;
