import ModalApp from "./ModalApp";
import { Box } from "@material-ui/core";
import { styled } from "@mui/material";
import { H4 } from "../../Typography";

const ModalMessage = (props: {
  message?: string;
  open?: boolean;
  positiveText?: string;
  negativeText?: string;
  onResult?: (result: boolean) => void;
  onClose?: (close: boolean) => void;
}) => {
  const onPositive = () => {
    if (props?.onResult != null) props?.onResult(true);
    onClose();
  };
  const onNegative = () => {
    if (props?.onResult != null) props?.onResult(false);
    onClose();
  };
  const onClose = () => {
    if (props?.onClose != null) props?.onClose(false);
  };
  return (
    <div>
      <ModalApp
        title={props?.message ? props?.message : ""}
        open={props?.open ? props?.open : false}
        onClose={() => (props?.onClose ? props?.onClose(false) : undefined)}
      >
        <br />
        <br />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            textAlign: "center",
            alignContent: "center",
          }}
        >
          <StyledButton
            hidden={props?.positiveText === undefined}
            onClick={onPositive}
          >
            {props?.positiveText}
          </StyledButton>
          <StyledButton
            hidden={props?.negativeText === undefined}
            onClick={onNegative}
            style={{ marginRight: 20, marginLeft: 20 }}
          >
            {props?.negativeText}
          </StyledButton>
        </Box>
      </ModalApp>
    </div>
  );
};

const StyledButton = styled(H4)(({ theme }) => ({
  marginRight: 15,
  marginLeft: 15,
  cursor: "pointer",
  color: theme.palette.primary.main,
  "&:hover": {
    color: theme.palette.primary.dark,
  },
}));

export default ModalMessage;
