import { ListSubheader, MenuItem, Select } from "@material-ui/core";
import { SelectInputProps } from "@material-ui/core/Select/SelectInput";
import { Typography } from "@mui/material";
import * as React from "react";
import { InputProps as StandardInputProps } from "@material-ui/core/Input/Input";
import useStyles from "./App-Select.styles";
import { ApplicationElementsMessages } from "utils/AppMessages/AppMessages";

const AppSelectGroup = (props: {
  id?: string;
  label?: string;
  style?: React.CSSProperties;
  inputProps?: StandardInputProps["inputProps"];
  dir?: string;
  data?: SelectGroupModel[];
  size?: Number;
  disable?: boolean;
  hidden?: boolean;
  defaultValue?: Number;
  className?: string;
  onChange?: SelectInputProps["onChange"];
}) => {
  const classes = useStyles();
  if (props.hidden) return <div />;

  let list = props.data ? props.data : [];
  let listItem = [];
  listItem.push(
    <MenuItem key={-1} value="">
      <em>{ApplicationElementsMessages.Choose}</em>
    </MenuItem>
  );
  for (let indexHeader = 0; indexHeader < list.length; indexHeader++) {
    const header = list[indexHeader];
    listItem.push(
      <ListSubheader key={header.id}>{header.title}</ListSubheader>
    );
    for (let indexItem = 0; indexItem < header.list.length; indexItem++) {
      const item = header.list[indexItem];
      listItem.push(
        <MenuItem key={item.id} value={item.id}>
          {item.title}
        </MenuItem>
      );
    }
  }
  let getDefaultValue = 0;
  if (list.length > 0) getDefaultValue = list[0].list[0].id;
  return (
    <div className={props.className}>
      <div className={classes.div}>
        <div className={classes.divLabel}>
          <Typography className={classes.label}>{props.label}</Typography>
        </div>
        <div>
          <Select
            fullWidth={true}
            required
            style={props.style}
            className={classes.view}
            id={props.id}
            dir={props.dir ? props.dir : "rtl"}
            value={props.defaultValue ? props.defaultValue : getDefaultValue}
            onChange={props.onChange}
            inputProps={props.inputProps}
            disabled={props.disable}
            variant="outlined"
          >
            {listItem}
          </Select>
        </div>
      </div>
    </div>
  );
};

export class SelectGroupModel {
  id: number = 0;
  title: string = "";
  list: SelectModel[] = [];
}

export class SelectModel {
  id: number = 0;
  title: string = "";
}

export default AppSelectGroup;
